import { gql } from '@apollo/client';

export const GET_LINE_ITEMS_FOR_EXPORT = gql`
  query GET_LINE_ITEMS_FOR_EXPORT(
    $adProductList: [String!]
    $pacingRiskList: [String!]
    $hasMisalignedSchedule: Boolean
    $pacingStatusList: [String!]
    $reviewStatusList: [String!]
    $adStatusList: [String!]
    $ids: [ID!]!
    $campaignIds: [ID!]!
    $traffickerEmailList: [String!]
    $accountExecutiveList: [String!]
    $orderTypeList: [String!]
    $tierNameList: [String!]
    $priorityValueList: [String!]
    $billableThirdPartyList: [String!]
    $publisherList: [String!]
    $statusList: [String!]
    $subStatusList: [String!]
    $adRatingList: [String!]
    $adTypeList: [String!]
    $creativeTypeList: [String!]
    $creativeReviewList: [String!]
    $isCoppaOrCaru: Boolean
    $isMakegood: Boolean
    $isAddedValue: Boolean
    $brandIdList: [ID!]
    $agencyIdList: [ID!]
    $industryIdList: [ID!]
    $currencyList: [String!]
    $isRotation: Boolean
    $isSequence: Boolean
    $countryList: [String!]
    $schedule: ScheduleFilterInputV5
    $sort: LineItemSortOptionV5
    $order: OrderOption
    $limit: Int
    $offset: Int
    $searchTerm: String
  ) {
    lineItems: lineItemsV5(
      filterOptions: {
        selectedIdList: $ids
        campaignIdList: $campaignIds
        traffickerEmailList: $traffickerEmailList
        accountExecutiveList: $accountExecutiveList
        schedule: $schedule
        searchTerm: $searchTerm
        adProductList: $adProductList
        pacingRiskList: $pacingRiskList
        hasMisalignedSchedule: $hasMisalignedSchedule
        pacingStatusList: $pacingStatusList
        reviewStatusList: $reviewStatusList
        adStatusList: $adStatusList
        orderTypeList: $orderTypeList
        statusList: $statusList
        subStatusList: $subStatusList
        tierNameList: $tierNameList
        priorityValueList: $priorityValueList
        billableThirdPartyList: $billableThirdPartyList
        publisherList: $publisherList
        adRatingList: $adRatingList
        adTypeList: $adTypeList
        creativeTypeList: $creativeTypeList
        creativeReviewList: $creativeReviewList
        isCoppaOrCaru: $isCoppaOrCaru
        isMakegood: $isMakegood
        isAddedValue: $isAddedValue
        brandIdList: $brandIdList
        agencyIdList: $agencyIdList
        industryIdList: $industryIdList
        currencyList: $currencyList
        isRotation: $isRotation
        isSequence: $isSequence
        countryList: $countryList
      }
      sortOptions: { sort: $sort, order: $order }
      paginationOptions: { limit: $limit, offset: $offset }
    ) {
      edges {
        node {
          __typename
          id
          pacingMetrics {
            yodaPacingStatus
            generalPacingStatus
            yodaThreshold
          }
          pacingType
          trackingId
          name
          tier {
            huluCommitment
            name
            rank
          }
          priority {
            name
            rank
          }
          review
          status
          subStatus
          parentLineOmsLink {
            id
          }
          lineOmsLink {
            id
          }
          adProduct
          billableThirdParty
          unitCost
          unitCostDto {
            currency
            micros
          }
          costMethod
          secondRoundEligible
          dhxpBackfill
          creativeMaxSeconds
          exclusiveBlock {
            id
            name
            lineItemIdList
          }
          draxDeal {
            packageId
            packageName
            dealId
            dealName
            dspId
          }
          campaign {
            id
            name
            traffickerEmail
            order {
              salespersonEmail
            }
            adAccount {
              id
              timezone
              organizationId
            }
            campaignTagList {
              id
              type
              url
            }
            crmLink {
              id
              name
            }
            startDate
          }
          lineItemSequenceId
          revenue
          priorityValue
          schedule {
            timezone
            dateRangeList {
              startDate
              endDate
              pacingShare
            }
            dayPartList {
              firstActiveHour
              lastActiveHour
              activeDayList
            }
            deliveryInViewerTime
          }
          frequencyCapList {
            type
            limit
            duration
            durationUnit
            enabled
          }
          deliveryBufferPercent
          frontLoadPercent
          preemptible
          goal
          isFiller
          podBuyout
          industryExclusive
          metrics {
            pacingStatus
            percentExpected
            percentPacing
            percentCompletions
            remainingExpectedCompletionsAcc
            revenueAtRisk
            completions
            underPacingRevenue
            completionsAtRisk
          }
          startDate
          endDate
          createdAt
          updatedAt
          hasZeroDeliveryRisk
          hasMisalignedSchedule
          deliveryGoal
          displayPublisherTarget
          isCoppaOrCaru
          isMakegood
          isAddedValue
          orderLineItemDto {
            parentLineOmsLink {
              id
              type
            }
            packageName
            childLineOmsLink {
              id
              type
            }
            isMakeGood
            buyerName
            buyerId
          }
          magniteDealDto {
            dealId
            dealName
            adSourceId
            adSourceName
            dspName
          }
          metadata {
            actualStartDate
          }
          maxCreativeMs
          country
          inventoryBlockId
        }
      }
      total
    }
  }
`;
