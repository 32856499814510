import { gql } from '@apollo/client';

import { CREATIVE_METADATA } from '../../../../common/AdForm/hooks/fragments/creativeMetadataFragment';

export const GET_CREATIVE_METADATA_BY_CREATIVE_ID = gql`
  query GetCreativeMetadataByCreativeId($creativeId: ID!) {
    getCreativeMetadataByCreativeIdV5(creativeId: $creativeId) {
      ...creativeMetadata
    }
  }
  ${CREATIVE_METADATA}
`;
