import type { TableInstance } from 'react-table';

import { getDataFromNodes } from '../../../../apis/graphql';
import type { TraffickingTableName } from '../../../../constants';
import type { ResultKeys } from '../../constants';
import type { EntityModel } from '../../TraffickingPage/modelConverters';
import type { TraffickerTableData } from '../../types';
import type { FetchDataResponse } from '../hooks/useTraffickerExportTable';
import type { LoadDataPerTable } from '../types';
import { getDataRowsToExport } from './utils';

type ProcessEntitesData = {
  data: TraffickerTableData;
  loadTableDataOptions: LoadDataPerTable;
  exportedIds: string[];
  tableName: TraffickingTableName;
  tableInstance: TableInstance<EntityModel>;
};

type ProcessEntitiesDataResponse = {
  newEntityIds: string[];
  newDataToExport: string[][];
};

export const processEntitiesDataToExport = ({
  data,
  loadTableDataOptions,
  exportedIds,
  tableName,
  tableInstance,
}: ProcessEntitesData): ProcessEntitiesDataResponse => {
  let entities = getDataFromNodes(data, loadTableDataOptions.resultKey, loadTableDataOptions.transformFn)();

  const uniqueEntitiesToExport = entities.filter((entity) => !exportedIds.includes(entity.id));

  const newEntityIds = uniqueEntitiesToExport.map((entity) => entity.id);

  const newDataToExport = getDataRowsToExport({
    tableName,
    tableInstance,
    entities: uniqueEntitiesToExport,
    selectedItems: loadTableDataOptions.selectedItems,
  });

  return {
    newEntityIds,
    newDataToExport,
  };
};

export const getNewCurrentTotal = (
  fetchEntitiesResponseData: FetchDataResponse[],
  resultKey: ResultKeys,
  currentTotal: number
): number => {
  if (!fetchEntitiesResponseData.length) {
    return currentTotal;
  }

  return fetchEntitiesResponseData[fetchEntitiesResponseData.length - 1].data
    ? fetchEntitiesResponseData[fetchEntitiesResponseData.length - 1].data[resultKey]?.total || currentTotal
    : currentTotal;
};
