import './AdPreviewForm.scss';

import React, { memo, useMemo, useState } from 'react';

import type { DayPartV5 } from '../../../../../../apis/graphql';
import { Creative, Details, Metadata, Scheduling, Tags, Targeting } from '../../../../../../common/AdForm';
import { AdFormSection } from '../../../../../../common/AdForm/AdSideNav/AdSideNav';
import CreativeBreadcrumb from '../../../../../../common/AdForm/CreativeBreadcrumb';
import { CreativeEditMode } from '../../../../../../common/AdForm/CreativeBuilders/types';
import { isNielsenTag, NIELSEN_CUTOFF_DAYS } from '../../../../../../common/AdForm/utils';
import DefaultFormSectionWrapper from '../../../../../../common/DefaultFormSectionWrapper';
import { useFieldArray } from '../../../../../../common/Form/hooks';
import type { ClearableFrequencyCap } from '../../../../../../common/FrequencyCaps/FrequencyCaps';
import type { AdProduct } from '../../../../../../configs';
import { getPermittedAdTypes } from '../../../../../../configs';
import type { AdTag, DateRange as Range, TargetingTermValue } from '../../../../../../models';
import bem from '../../../../../../utils/bem';
import { getConflictLineItemsIdsWithAdDateRangeList } from '../../../../../../utils/validation';
import { getPublisherTargetingList } from '../../../../TraffickingPage/columns';
import type { AdModel } from '../../../../TraffickingPage/modelConverters';
import type { DuplicateLineItem } from '../../hooks/useDuplicateLineItemsState';
import SelectableAdFormSectionWrapper from './SelectableAdFormSectionWrapper';
import { getScheduleErrorMessage } from './SelectableAdFormSectionWrapper/utils';

type AdPreviewFormProps = {
  lineItem: AdModel['lineItem'];
  lineItemTargetingTermValues: TargetingTermValue[];
  duplicateLineItems: DuplicateLineItem[];
};

const [block, element] = bem('ad-preview-form');

export const NIELSEN_TAGS_CANNOT_COPY_MSG = `Cannot copy over the existing Disney-generated DAR tag. A new DAR tag will be generated unless the campaign starts beyond ${NIELSEN_CUTOFF_DAYS} days.`;
const AD_TAG_LIST_FILED = 'adTagList';

const AdPreviewForm = ({
  lineItem,
  lineItemTargetingTermValues,
  duplicateLineItems,
}: AdPreviewFormProps): JSX.Element => {
  const permittedAdTypes = getPermittedAdTypes(lineItem?.adProduct.key as AdProduct);
  const publisher = getPublisherTargetingList({ value: lineItem?.displayPublisherTarget || null });

  const [stashedDayPartList, setStashedDayPartList] = useState<DayPartV5[]>([]);
  const [stashedFrequencyCapList, setStashedFrequencyCapList] = useState<ClearableFrequencyCap[]>([]);
  const [, { value: dateRangeList }] = useFieldArray<Range>('schedule.dateRangeList');
  const [, { value: adTagList }, { removeAt, replaceAt, push }] = useFieldArray<AdTag>(AD_TAG_LIST_FILED);

  const scheduleError = useMemo(() => {
    const ids = getConflictLineItemsIdsWithAdDateRangeList(duplicateLineItems, dateRangeList);

    return ids.length ? getScheduleErrorMessage(ids) : '';
  }, [dateRangeList, duplicateLineItems]);

  const tagsError = useMemo(() => {
    if (adTagList.some((tag) => isNielsenTag(tag.url))) {
      return {
        message: NIELSEN_TAGS_CANNOT_COPY_MSG,
        isError: false,
      };
    }
    return null;
  }, [adTagList]);

  const listHelpers = useMemo(() => ({ removeAt, replaceAt, push }), [removeAt, replaceAt, push]);
  const nonNielsenAdTags = useMemo(() => adTagList.filter((adTag) => !isNielsenTag(adTag.url)), [adTagList]);

  return (
    <form className={block()} id="ad-preview-form-id" data-testid="ad-preview-form">
      <div className={element('content')}>
        <DefaultFormSectionWrapper title="Details" sectionName={AdFormSection.DETAILS}>
          <Details permittedAdTypes={permittedAdTypes} isDeliveryStatusDisabled readonly />
        </DefaultFormSectionWrapper>
        <SelectableAdFormSectionWrapper
          title="Creative"
          sectionName={AdFormSection.CREATIVE}
          customTitle={<CreativeBreadcrumb readonly />}
        >
          <Creative lineItemType={'UNKNOWN'} editMode={CreativeEditMode.readonly} />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper title="Tags for Third Party Measurement" sectionName={AdFormSection.TAGS}>
          <Tags
            isAdInitiallyWithBillableTag
            unDeletableAd
            readonly
            adTagList={nonNielsenAdTags}
            listHelpers={listHelpers}
            nielsenTagWarning={tagsError}
          />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper title="Targeting" sectionName={AdFormSection.TARGETING}>
          <Targeting
            lineItemTargetingTermValues={lineItemTargetingTermValues}
            publisher={publisher}
            readonly
            publisherId={(lineItem?.displayPublisherTarget && lineItem?.displayPublisherTarget[0]) || ''}
          />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper
          error={scheduleError}
          disabled={Boolean(scheduleError)}
          title="Scheduling"
          sectionName={AdFormSection.SCHEDULING}
        >
          <Scheduling
            lineItemSchedule={lineItem?.schedule}
            stashedDayPartList={stashedDayPartList}
            setStashedDayPartList={setStashedDayPartList}
            readonly
          />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper title="Metadata" sectionName={AdFormSection.METADATA}>
          <Metadata
            stashedFrequencyCapList={stashedFrequencyCapList}
            setStashedFrequencyCapList={setStashedFrequencyCapList}
            readonly
          />
        </SelectableAdFormSectionWrapper>
      </div>
    </form>
  );
};

export default memo(AdPreviewForm);
