import './MultipleEditDrawerContentSecondStep.scss';

import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';

import type { DayPartV5 } from '../../../../../../../apis/graphql';
import { validateTagList } from '../../../../../../../common/AdForm/validation/tags';
import Button from '../../../../../../../common/Button';
import { useFieldArray, useFieldFast } from '../../../../../../../common/Form/hooks';
import SideNav from '../../../../../../../common/SideNav';
import { showFailure } from '../../../../../../../common/utils';
import type { Ad, AdTag, LineItem, TargetingTermValue } from '../../../../../../../models';
import bem from '../../../../../../../utils/bem';
import { EntityTypeArray } from '../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { MultipleEditDrawerFormValues } from '../../../types';
import MultipleEditDrawerContentCard from '../../MultipleEditDrawerContentCard';
import MultipleEditDrawerContentHeader from '../../MultipleEditDrawerContentHeader';
import { adsCardsContent, lineItemsCardsContent } from './cardsContent';
import { scrollContainerId } from './constants';
import { getEntitiesWithOriginalProps, getIsSaveButtonDisabled } from './utils';

const [block, element] = bem('multiple-edit-drawer-content-second-step');

export type MultipleEditDrawerContentSecondStepProps = {
  isExpanded: boolean;
  entityType: EntityTypeArray;
  onNextStep: () => void;
};

const EDIT_SECTION_MESSAGE =
  "Check the box to make changes in this section. If the section is not checked, edits won't be applied.";

const extractTargetingTerms = (lineItem: LineItem | null, ad: Ad | null): TargetingTermValue[] => {
  if (lineItem) {
    return lineItem.targetingTermValues || [];
  } else {
    return ad?.lineItem?.targetingTermValues || [];
  }
};

const extractPublisher = (lineItem: LineItem | null, ad: Ad | null): String[] => {
  if (lineItem) {
    return lineItem.displayPublisherTarget || [];
  } else {
    return ad?.lineItem?.displayPublisherTarget || [];
  }
};

export default function MultipleEditDrawerContentSecondStep({
  isExpanded,
  entityType,
  onNextStep,
}: MultipleEditDrawerContentSecondStepProps): JSX.Element {
  const { resetForm, values, setFieldValue } = useFormikContext<MultipleEditDrawerFormValues>();
  const isLineItem = entityType === EntityTypeArray.LINE_ITEM_ARRAY;
  const [stashedDayPartList, setStashedDayPartList] = useState<DayPartV5[]>([]);

  const [, { value: adTagList }] = useFieldArray<AdTag>('adTagList');

  const tagsError = useMemo(() => validateTagList(adTagList ?? []), [adTagList]);

  const cardsContent = isLineItem
    ? lineItemsCardsContent
    : adsCardsContent({ stashedDayPartList, setStashedDayPartList });
  const sideNavLinks = cardsContent.map(({ sideNavLink }) => sideNavLink);

  const onDiscardChanges = (): void => {
    resetForm();

    const entitiesWithOriginalIsSelectedAndStatus = getEntitiesWithOriginalProps({
      entities: values.entities,
      initialStatuses: values.initialStatuses,
    });

    setFieldValue('entities', entitiesWithOriginalIsSelectedAndStatus);
    setFieldValue('initialStatuses', values.initialStatuses);
  };

  const onSave = (): void => {
    if (tagsError) {
      const { message, description } = tagsError;

      showFailure({ message, description });
    } else {
      onNextStep();
    }
  };

  const [, { value: entities = [] }] = useFieldFast<LineItem[] | Ad[]>('entities');
  const targetingTerms: TargetingTermValue[] = entities
    .map((item: LineItem | Ad) => {
      return [...extractTargetingTerms(isLineItem ? (item as LineItem) : null, !isLineItem ? (item as Ad) : null)];
    })
    .flat(1);

  const countries: Set<String> = new Set();
  const publishers: Set<String> = new Set(
    entities
      .map((item: LineItem | Ad) => {
        return extractPublisher(isLineItem ? (item as LineItem) : null, !isLineItem ? (item as Ad) : null);
      })
      .flat(1)
  );

  targetingTerms.forEach((term: TargetingTermValue): void => {
    if (term.dimension.id === 'country') {
      countries.add(term.value.id);
    }
  });

  const targetingErrorMsg: string = `Cannot be edited on lines/ads targeting with different publishers or countries`;

  return (
    <div className={block()}>
      <MultipleEditDrawerContentHeader>
        <div className={element('buttons')}>
          <Button size="medium" onClick={onDiscardChanges} disabled={!values.selectedSections.length}>
            Discard Changes
          </Button>
          <Button size="medium" onClick={onSave} disabled={getIsSaveButtonDisabled({ values, isLineItem })}>
            Save
          </Button>
        </div>
      </MultipleEditDrawerContentHeader>
      <div className={element('edit-message')}>{EDIT_SECTION_MESSAGE}</div>
      <div className={element('body', isLineItem ? 'line-items' : 'ads')}>
        <SideNav links={sideNavLinks} containerId={scrollContainerId} testid="multiple-edit-side-nav" />
        <div className={element('body__content')} id={scrollContainerId}>
          {cardsContent.map(({ sideNavLink, cardContent, title }) => {
            const disableCheckbox: boolean =
              countries.size === 1 && publishers.size === 1 ? false : sideNavLink === 'Targeting' ? true : false;

            return (
              <MultipleEditDrawerContentCard
                sideNavLink={sideNavLink}
                key={sideNavLink}
                title={title}
                disabled={disableCheckbox}
                errorMsg={disableCheckbox ? targetingErrorMsg : undefined}
              >
                {cardContent(isExpanded, entityType)}
              </MultipleEditDrawerContentCard>
            );
          })}
        </div>
      </div>
    </div>
  );
}
