import { deliveryTiers } from '../../../../../../../../../configs';
import type { Nullable } from '../../../../../../../../../models';

interface LineItemConfig {
  priorityValue: Nullable<number>;
  tierNameKeyConfig: string;
  priorityNameConfig: string;
}

//TODO remove this BE logic and depending functions to avoid code obsolescence.
// Use a separate endpoint to check line item change possibility and to get priority-value
export function getPriorityValue(tierNameKey: string, priorityName: string): Nullable<number> {
  const configs: LineItemConfig[] = [
    { priorityValue: 2, tierNameKeyConfig: deliveryTiers.sponsorship.key, priorityNameConfig: 'High' },
    { priorityValue: 3, tierNameKeyConfig: deliveryTiers.sponsorship.key, priorityNameConfig: 'Standard' },
    { priorityValue: 4, tierNameKeyConfig: deliveryTiers.sponsorship.key, priorityNameConfig: 'Low' },
    { priorityValue: 6, tierNameKeyConfig: deliveryTiers.guaranteed.key, priorityNameConfig: 'Very_high' },
    { priorityValue: 7, tierNameKeyConfig: deliveryTiers.guaranteed.key, priorityNameConfig: 'High' },
    { priorityValue: 8, tierNameKeyConfig: deliveryTiers.guaranteed.key, priorityNameConfig: 'Standard' },
    { priorityValue: 9, tierNameKeyConfig: deliveryTiers.guaranteed.key, priorityNameConfig: 'Low' },
    { priorityValue: 10, tierNameKeyConfig: deliveryTiers.non_guaranteed.key, priorityNameConfig: 'High' },
    { priorityValue: 11, tierNameKeyConfig: deliveryTiers.non_guaranteed.key, priorityNameConfig: 'Standard' },
    { priorityValue: 12, tierNameKeyConfig: deliveryTiers.non_guaranteed.key, priorityNameConfig: 'Low' },
    { priorityValue: 15, tierNameKeyConfig: deliveryTiers.house.key, priorityNameConfig: 'High' },
    { priorityValue: 16, tierNameKeyConfig: deliveryTiers.house.key, priorityNameConfig: 'Standard' },
    { priorityValue: 17, tierNameKeyConfig: deliveryTiers.house.key, priorityNameConfig: 'Low' },
    { priorityValue: 20, tierNameKeyConfig: deliveryTiers.test.key, priorityNameConfig: 'Low' },
  ];

  const config = configs.find(
    (lineItemConfig) =>
      lineItemConfig.tierNameKeyConfig === tierNameKey && lineItemConfig.priorityNameConfig === priorityName
  );

  return config?.priorityValue!;
}
