import type { Dispatch } from 'react';
import { useCallback, useMemo } from 'react';

import type { TraffickingFilters } from '../../../../apis/graphql';
import type { SearchParamsValuesType } from '../../../../constants';
import type { AdditionalFilterDropdown, SelectedFiltersValues } from '../AdditionalFilters/constants';
import { convertSelectedFilterValueToRightFormat } from '../utils';

type HookProps = {
  selectedDropdownFilters: AdditionalFilterDropdown[];
  onSelectedDropdownFilters: Dispatch<React.SetStateAction<AdditionalFilterDropdown[]>>;
  selectedFiltersValues: SelectedFiltersValues;
  applyCommonFilter: <T extends keyof TraffickingFilters>(filterName: T, filterValue: TraffickingFilters[T]) => void;
};

type ReturnedHookType = {
  transformedSelectedDropdownFilters: AdditionalFilterDropdown[];
  isFilterChipShown: boolean;
  onRemoveSingleFilter: (selectedSearchParam: SearchParamsValuesType) => void;
  onRemoveAllFilter: () => void;
};

export const useHandleDropdownFilters = ({
  selectedFiltersValues,
  selectedDropdownFilters,
  applyCommonFilter,
  onSelectedDropdownFilters,
}: HookProps): ReturnedHookType => {
  const transformedSelectedDropdownFilters = useMemo(
    () =>
      selectedDropdownFilters
        .map((filter) => ({
          ...filter,
          selectedFilterValues: convertSelectedFilterValueToRightFormat(
            selectedFiltersValues[filter.searchParam] ?? [],
            filter.searchParam
          ),
        }))
        .filter((filter) => filter.selectedFilterValues.length > 0),
    [selectedDropdownFilters, selectedFiltersValues]
  );

  const isFilterChipShown = useMemo(() => !!transformedSelectedDropdownFilters.length, [
    transformedSelectedDropdownFilters,
  ]);

  const onRemoveSingleFilter = useCallback(
    (selectedSearchParam: SearchParamsValuesType): void => {
      if (selectedFiltersValues[selectedSearchParam]?.length) {
        applyCommonFilter(selectedSearchParam, []);
      }

      onSelectedDropdownFilters((prevSelectedFiltersDropdowns) =>
        prevSelectedFiltersDropdowns.filter((filter) => filter.searchParam !== selectedSearchParam)
      );
    },
    [applyCommonFilter, selectedFiltersValues, onSelectedDropdownFilters]
  );

  const onRemoveAllFilter = useCallback(() => {
    const selectedFilters = transformedSelectedDropdownFilters.flatMap((filter) => filter.searchParam);

    if (selectedFilters.length) {
      selectedFilters.forEach((filter) => {
        applyCommonFilter(filter, []);
      });
    }

    onSelectedDropdownFilters([]);
  }, [transformedSelectedDropdownFilters, applyCommonFilter, onSelectedDropdownFilters]);

  return {
    transformedSelectedDropdownFilters,
    isFilterChipShown,
    onRemoveSingleFilter,
    onRemoveAllFilter,
  };
};
