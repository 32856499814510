import { Navigate } from '@hulu/react-router-dom';
import type { FC } from 'react';
import React from 'react';

import { PathName } from '../../constants';
import { LOCATION_BEFORE_LOGIN } from '../constants';

const RootRedirect: FC = () => {
  const item = localStorage.getItem(LOCATION_BEFORE_LOGIN);

  if (item) {
    localStorage.removeItem(LOCATION_BEFORE_LOGIN);
    return <Navigate to={JSON.parse(item)} replace />;
  }

  return <Navigate to={PathName.trafficking} replace />;
};

export default RootRedirect;
