import { useState } from 'react';
import type { TableInstance } from 'react-table';

import type { NodeTransformFunction } from '../../../../apis/graphql';
import { AdSortOptionV5, CampaignSortOptionV5, LineItemSortOptionV5, OrderOption } from '../../../../apis/graphql';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import type { GlobalFilters } from '../../../../contexts/FilterContext/hooks';
import { ResultKeys } from '../../constants';
import type { QueryVariables } from '../../TraffickingPage/hooks';
import type { EntityModel } from '../../TraffickingPage/modelConverters';
import { generateQueryVariables, getSelectedRowIdsFromState } from '../../utils';
import { CHUNK_SHIFT, COUNT_RETRIES, COUNT_ROWS_TO_SHOW_WARNING, EXPORT_LIMIT_PER_PAGE } from '../constants';
import { adNodeToModelForExport } from '../convertors/ad';
import { campaignNodeToModelForExport } from '../convertors/campaign';
import { lineItemNodeToModelForExport } from '../convertors/lineItem';
import { GET_ADS_FOR_EXPORT } from '../queries/getAds';
import { GET_CAMPAIGNS_FOR_EXPORT } from '../queries/getCampaigns';
import { GET_LINE_ITEMS_FOR_EXPORT } from '../queries/getLineItems';
import type { EntityModelForExport, EntityNodeForExport, LoadDataPerTable } from '../types';
import { getNewCurrentTotal, processEntitiesDataToExport } from '../utils/loadAllDataUtils';
import { getHeaderRowToExport, getReversedEntities, getTableDataToExport } from '../utils/utils';
import useTraffickerExportTable from './useTraffickerExportTable';

type ExportAllState = {
  loadAllData: () => Promise<void>;
  loadTableData: () => void;
  loading: boolean;
  dataToExport: string[][];
};

const useTraffickerExportState = (
  tableName: TraffickingTableName,
  tableInstance: TableInstance<EntityModel>,
  total: number
): ExportAllState => {
  const [loading, setLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState<string[][]>([]);
  const { filters } = useFiltersContext();

  const getCampaignQueryVariables = (queryFilters: GlobalFilters, offset: number): QueryVariables => {
    return generateQueryVariables(
      {
        ...queryFilters,
        searchTerm: queryFilters?.campaigns?.searchTerm || '',
        sortOption: {
          sort: CampaignSortOptionV5.CreatedAt,
          order: OrderOption.Asc,
        },
        tableName: TraffickingTableName.campaigns,
        selectedRowIds: getSelectedRowIdsFromState(queryFilters.campaigns?.selectedRowIds || []),
      },
      EXPORT_LIMIT_PER_PAGE,
      offset
    );
  };

  const getLineItemQueryVariables = (queryFilters: GlobalFilters, offset: number): QueryVariables => {
    return generateQueryVariables(
      {
        ...queryFilters,
        searchTerm: queryFilters?.lineItems?.searchTerm || '',
        sortOption: {
          sort: LineItemSortOptionV5.CreatedAt,
          order: OrderOption.Asc,
        },
        tableName: TraffickingTableName.lineItems,
        selectedRowIds: getSelectedRowIdsFromState(queryFilters.lineItems?.selectedRowIds || []),
        campaignRowsIds: getSelectedRowIdsFromState(queryFilters.campaigns?.selectedRowIds || []),
      },
      EXPORT_LIMIT_PER_PAGE,
      offset
    );
  };

  const getAdsQueryVariables = (queryFilters: GlobalFilters, offset: number): QueryVariables => {
    return generateQueryVariables(
      {
        ...queryFilters,
        searchTerm: queryFilters?.ads?.searchTerm || '',
        sortOption: {
          sort: AdSortOptionV5.CreatedAt,
          order: OrderOption.Asc,
        },
        tableName: TraffickingTableName.ads,
        selectedRowIds: getSelectedRowIdsFromState(queryFilters.ads?.selectedRowIds || []),
        campaignRowsIds: getSelectedRowIdsFromState(queryFilters.campaigns?.selectedRowIds || []),
        lineItemRowIds: getSelectedRowIdsFromState(queryFilters.lineItems?.selectedRowIds || []),
      },
      EXPORT_LIMIT_PER_PAGE,
      offset
    );
  };

  const loadAllEntitiesPerTableData: Record<TraffickingTableName, LoadDataPerTable> = {
    [TraffickingTableName.ads]: {
      selectedItems: filters.ads?.selectedRowIds || [],
      getQueryVariables: getAdsQueryVariables,
      resultKey: ResultKeys.ads,
      query: GET_ADS_FOR_EXPORT,
      transformFn: adNodeToModelForExport as NodeTransformFunction<EntityNodeForExport, EntityModelForExport>,
    },
    [TraffickingTableName.lineItems]: {
      selectedItems: filters.lineItems?.selectedRowIds || [],
      getQueryVariables: getLineItemQueryVariables,
      resultKey: ResultKeys.lineItems,
      query: GET_LINE_ITEMS_FOR_EXPORT,
      transformFn: lineItemNodeToModelForExport as NodeTransformFunction<EntityNodeForExport, EntityModelForExport>,
    },
    [TraffickingTableName.campaigns]: {
      selectedItems: filters.campaigns?.selectedRowIds || [],
      getQueryVariables: getCampaignQueryVariables,
      resultKey: ResultKeys.campaigns,
      query: GET_CAMPAIGNS_FOR_EXPORT,
      transformFn: campaignNodeToModelForExport as NodeTransformFunction<EntityNodeForExport, EntityModelForExport>,
    },
  };

  const { fetchData: fetchEntities } = useTraffickerExportTable(loadAllEntitiesPerTableData[tableName].query);

  const onLoadAllDataFinished = (fullDataToExport: string[][], showError: boolean): void => {
    if (!showError) {
      setDataToExport([getHeaderRowToExport(tableInstance), ...getReversedEntities(fullDataToExport)]);
    } else {
      openToastAlert({
        alertType: 'error',
        message: `Export failed.`,
        description: `Something went wrong during export. Please try again later.`,
      });
    }

    setLoading(false);
  };

  const handleShowWarning = (): void => {
    if (total > COUNT_ROWS_TO_SHOW_WARNING) {
      openToastAlert({
        alertType: 'warning',
        message: `Export in progress.`,
        description: `Export in progress. File will automatically download.`,
      });
    }
  };

  const loadAllData = async (): Promise<void> => {
    setLoading(true);
    setDataToExport([]);
    const queryFilters = filters;
    const loadAllEntitiesData = loadAllEntitiesPerTableData[tableName];
    let offset = 0;
    const exportedIds: string[] = [];
    const fullDataToExport: string[][] = [];
    /*
      We can only make a limited number of parallel requests in the browser, so it’s not possible to fetch all items at once.
      To retrieve a large number of rows (more than 5,000), we fetch data in a loop, with 5 parallel requests in each iteration.
      Each batch, called as a 'chunk' in the implementation, retrieves 5 requests * 1,000 items per request = 5,000 items per chunk.
      So, if we need to export 17,000 items, we will have 4 chunks.
    */
    let chunksLeft = true;
    let errorOccurred = false;
    let currentTotal = total;

    handleShowWarning();

    while (chunksLeft && !errorOccurred) {
      const fetchEntitiesResponseData = [];

      let dataFetched = false;
      let countRetries = COUNT_RETRIES;
      while (!dataFetched && countRetries > 0) {
        const queryVariables = loadAllEntitiesData.getQueryVariables(queryFilters, offset);
        const fetchDataResponse = await fetchEntities(queryVariables);

        if (fetchDataResponse.data && !(fetchDataResponse.errors && fetchDataResponse.errors.length)) {
          fetchEntitiesResponseData.push(fetchDataResponse);
          dataFetched = true;
        }
        countRetries -= 1;
      }
      offset += EXPORT_LIMIT_PER_PAGE;
      if (!dataFetched) {
        errorOccurred = true;
      }

      // eslint-disable-next-line no-loop-func
      fetchEntitiesResponseData.forEach((fetchEntitiesResponse) => {
        const { data, errors } = fetchEntitiesResponse;
        if (!data || (errors && errors.length)) {
          errorOccurred = true;
        }

        if (errorOccurred) return;

        const { newEntityIds, newDataToExport } = processEntitiesDataToExport({
          data,
          loadTableDataOptions: loadAllEntitiesData,
          exportedIds,
          tableName,
          tableInstance,
        });

        exportedIds.push(...newEntityIds);
        fullDataToExport.push(...newDataToExport);
      });

      currentTotal = getNewCurrentTotal(fetchEntitiesResponseData, loadAllEntitiesData.resultKey, total);

      if (offset >= currentTotal) {
        chunksLeft = false;
      }

      /*
        There may be a case when we could miss some items.
        For example: we have 17,000 items. So we have 4 chunks.
        Suppose the first and second chunks have been fetched, and the third chunk is currently being fetched.
        And at the same time an item from the already-fetched first or second chunk might be removed.
        This could result in missing items from the final export.
        To mitigate this issue, we implement an offset shift after each chunk.
        So, we re-fetch the last 100 items from the previous chunk to reduce the amount of missed items.
       */
      offset -= CHUNK_SHIFT;
    }

    onLoadAllDataFinished(fullDataToExport, errorOccurred);
  };

  const loadTableData = (): void => {
    setLoading(true);
    setDataToExport([]);
    const dataToExport = getTableDataToExport({
      tableName,
      tableInstance,
      selectedItems: loadAllEntitiesPerTableData[tableName].selectedItems,
    });
    setDataToExport(dataToExport);
    setLoading(false);
  };

  return {
    loadAllData,
    loadTableData,
    loading,
    dataToExport,
  };
};

export default useTraffickerExportState;
