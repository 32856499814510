import type { DocumentNode } from 'graphql';

import type {
  Agency,
  Brand,
  Get_AgenciesQuery,
  Get_BrandsQuery,
  Get_Industries_V5Query,
  Industry,
  PageInfo,
} from '../../apis/graphql';
import { CampaignReviewStatus, CrmLinkTypeV5, GeneralPacingStatus, PacingStatus } from '../../apis/graphql';
import {
  convertAdProduct,
  convertAdRating,
  convertAdType,
  convertAssetReviewStatus,
  convertBillableThirdParty,
  convertCreative,
  convertDeliveryTier,
  convertPublisher,
} from '../../configs';
import { convertCurrencyTypes } from '../../configs/currencyTypes';
import { GET_AD_PRODUCT } from '../../pages/Trafficking/TableFilterBar/hooks/queries';
import { GET_CURRENCY_TYPES } from '../../pages/Trafficking/TableFilterBar/hooks/queries/getCurrencyTypes';
import { LINE_ITEMS_STATUSES, LINE_ITEMS_SUB_STATUSES } from '../../pages/Trafficking/TraffickingPage/hooks/constants';
import type { DropdownOption } from '../Dropdown';
import { getTimezoneWithGMT } from '../SelectTimeZone/utils';
import { DEFAULT_FILTER_LIMIT, DEFAULT_FILTER_OFFSET } from './constants';
import { GET_AD_PERMITTED_TIMEZONES, GET_AD_RATINGS } from './schemas';
import { GET_AD_TYPES } from './schemas/getAdTypes';
import { GET_AGENCIES } from './schemas/getAgencies';
import { GET_BRANDS } from './schemas/getBrands';
import { GET_CREATIVE_REVIEW_STATUSES } from './schemas/getCreativeReviewStatuses';
import { GET_CREATIVE_TYPES } from './schemas/getCreativeTypes';
import { GET_INDUSTRIES_V5 } from './schemas/getIndustriesV5';
import { GET_LINE_ITEM_BILLABLE_THIRD_PARTIES } from './schemas/getLineItemBillableThirdParties';
import { GET_LINE_PRIORITY_VALUES } from './schemas/getLineItemPriorityValues';
import { GET_LINE_ITEM_TIRE_NAMES } from './schemas/getLineItemTierNames';
import { GET_ORDER_TYPES } from './schemas/getOrderTypes';
import { GET_PUBLISHER_LIST } from './schemas/getPublisherList';
import { convertQueryResultToDropdownOptions } from './utils';

export enum DropdownOptions {
  AdRating,
  AdPermittedTimezones,
  OrderTypes,
  LineItemBillableThirdParties,
  LineItemPriorityValues,
  LineItemTierNames,
  PublisherList,
  AdType,
  CreativeType,
  CreativeReviewStatus,
  ProductType,
  CurrencyList,
}

export enum MultiDropdownOptions {
  Brands,
  Agencies,
  Industries,
}

export enum PredefinedDropdownOptions {
  IsCoppaOrCaru,
  IsAddedValue,
  IsMakegood,
  AdStatus,
  LineItemStatus,
  PacingStatus,
  GeneralPacingStatus,
  ReviewStatus,
  isRotation,
  isSequence,
  countryList,
}

export type LabelTransformationFunction = (label: string) => string;
export type DropdownOptionConfig = {
  /**
   * @param {string} queryName - defines a specific query that will be used to fetch data
   * @example 'getLineItemTierNamesV5'
   */
  queryName: string;
  /**
   * @param {DocumentNode} schema: defines a schema for specific query that will be used to fetch data
   * @example 'GET_LINE_ITEM_TIRE_NAMES'
   */
  schema: DocumentNode;
  /**
   * @param {LabelTransformationFunction} labelTransformationFunction: defines a function which is responsible for a label transformation for a particular dropdown options
   * @example labelTransformationFunction: (tireName: string) => convertDeliveryTier(tireName).displayName,
   */
  labelTransformationFunction?: LabelTransformationFunction;
};

type MultiDropdownQueryName = 'brands' | 'getAgencies' | 'getIndustriesV5';
type QueryOptions = { skip: boolean } | { variables: { searchTerm: string; offset: number; limit: number } };
type AdditionalQueryVariables = { crmLinkTypeList: string[] };

export type MultiDropdownOptionConfig<T> = {
  schema: DocumentNode;
  queryName: MultiDropdownQueryName;
  queryOptions: QueryOptions;
  additionalQueryVariables?: AdditionalQueryVariables;
  convertFetchedData: (data: T | null | undefined) => DropdownOption<string>[];
  queryPageInfo: (data: T | null | undefined) => Pick<PageInfo, 'hasNextPage'>;
  queryOffset: (data: T | null | undefined) => number;
};

const commonBooleanFilterOptions = [
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
];

export const DROPDOWN_OPTIONS_CONFIG: Record<DropdownOptions, DropdownOptionConfig> = {
  [DropdownOptions.AdRating]: {
    queryName: 'getAdRatingsV5',
    schema: GET_AD_RATINGS,
    labelTransformationFunction: (adRating: string): string => convertAdRating(adRating).displayName,
  },
  [DropdownOptions.AdPermittedTimezones]: {
    queryName: 'getAdPermittedTimezones',
    schema: GET_AD_PERMITTED_TIMEZONES,
    labelTransformationFunction: (timezone: string): string => getTimezoneWithGMT(timezone),
  },
  [DropdownOptions.OrderTypes]: {
    queryName: 'getOrderTypes',
    schema: GET_ORDER_TYPES,
  },
  [DropdownOptions.LineItemBillableThirdParties]: {
    queryName: 'getLineItemBillableThirdPartiesV5',
    schema: GET_LINE_ITEM_BILLABLE_THIRD_PARTIES,
    labelTransformationFunction: (billable3p: string): string => convertBillableThirdParty(billable3p).displayName,
  },
  [DropdownOptions.LineItemPriorityValues]: {
    queryName: 'getLineItemPriorityValuesV5',
    schema: GET_LINE_PRIORITY_VALUES,
  },
  [DropdownOptions.LineItemTierNames]: {
    queryName: 'getLineItemTierNamesV5',
    schema: GET_LINE_ITEM_TIRE_NAMES,
    labelTransformationFunction: (tireName: string): string => convertDeliveryTier(tireName).displayName,
  },
  [DropdownOptions.PublisherList]: {
    queryName: 'getPublisherListV4',
    schema: GET_PUBLISHER_LIST,
    labelTransformationFunction: (publisher: string) => convertPublisher(publisher).displayName,
  },
  [DropdownOptions.AdType]: {
    queryName: 'getAdTypesV5',
    schema: GET_AD_TYPES,
    labelTransformationFunction: (adType: string) => convertAdType(adType).displayName,
  },
  [DropdownOptions.CreativeType]: {
    queryName: 'getCreativeTypesV5',
    schema: GET_CREATIVE_TYPES,
    labelTransformationFunction: (creativeType: string) => convertCreative(creativeType).displayName,
  },
  [DropdownOptions.CreativeReviewStatus]: {
    queryName: 'getCreativeReviewsStatusV5',
    schema: GET_CREATIVE_REVIEW_STATUSES,
    labelTransformationFunction: (reviewStatus: string) => convertAssetReviewStatus(reviewStatus),
  },
  [DropdownOptions.ProductType]: {
    queryName: 'getLineItemAdProductV5',
    schema: GET_AD_PRODUCT,
    labelTransformationFunction: (adType: string) => convertAdProduct(adType).displayName,
  },
  [DropdownOptions.CurrencyList]: {
    queryName: 'getCurrencyTypes',
    schema: GET_CURRENCY_TYPES,
    labelTransformationFunction: (currencyType: string) => convertCurrencyTypes(currencyType).displayName,
  },
};

export const PREDEFINED_DROPDOWN_OPTIONS_CONFIG: Record<PredefinedDropdownOptions, DropdownOption[]> = {
  [PredefinedDropdownOptions.IsAddedValue]: commonBooleanFilterOptions,
  [PredefinedDropdownOptions.IsCoppaOrCaru]: commonBooleanFilterOptions,
  [PredefinedDropdownOptions.IsMakegood]: commonBooleanFilterOptions,
  [PredefinedDropdownOptions.isRotation]: commonBooleanFilterOptions,
  [PredefinedDropdownOptions.isSequence]: commonBooleanFilterOptions,
  [PredefinedDropdownOptions.AdStatus]: [
    { value: 'Off', label: 'Off' },
    { value: 'On', label: 'On' },
  ],
  [PredefinedDropdownOptions.LineItemStatus]: [
    { value: LINE_ITEMS_STATUSES.CANCELLED, label: 'Cancelled' },
    { value: LINE_ITEMS_SUB_STATUSES.DID_NOT_RUN, label: 'Did Not Run' },
    { value: LINE_ITEMS_SUB_STATUSES.ENDED, label: 'Ended' },
    { value: LINE_ITEMS_SUB_STATUSES.LATE, label: 'Late' },
    { value: LINE_ITEMS_SUB_STATUSES.LIVE, label: 'Live' },
    { value: LINE_ITEMS_STATUSES.OFF, label: 'Off' },
    { value: LINE_ITEMS_STATUSES.ON, label: 'On' },
    { value: LINE_ITEMS_STATUSES.PAUSED, label: 'Paused' },
    { value: LINE_ITEMS_SUB_STATUSES.READY, label: 'Ready' },
  ],
  [PredefinedDropdownOptions.PacingStatus]: [
    { value: PacingStatus.OnPacing, label: 'On Pace' },
    { value: PacingStatus.OverPacing, label: 'Over-Pacing' },
    { value: PacingStatus.UnderPacing, label: 'Under-Pacing' },
  ],
  [PredefinedDropdownOptions.GeneralPacingStatus]: [
    { value: GeneralPacingStatus.OnPace, label: 'On Pace' },
    { value: GeneralPacingStatus.OverPacing, label: 'Over Pacing' },
    { value: GeneralPacingStatus.UnderPacing, label: 'Under Pacing' },
    { value: GeneralPacingStatus.Delivered, label: 'Delivered' },
    { value: GeneralPacingStatus.UnderDelivered, label: 'Under Delivered' },
    { value: GeneralPacingStatus.Cancelled, label: 'Cancelled' },
    { value: GeneralPacingStatus.DidNotRun, label: 'Did Not Run' },
    { value: GeneralPacingStatus.NotEven, label: 'Not Even' },
  ],
  [PredefinedDropdownOptions.ReviewStatus]: [
    { value: CampaignReviewStatus.Approved, label: 'Approved' },
    { value: CampaignReviewStatus.Draft, label: 'Draft' },
    { value: CampaignReviewStatus.UnderReview, label: 'Under Review' },
  ],
  [PredefinedDropdownOptions.countryList]: [
    { value: 'AR', label: 'Argentina' },
    { value: 'BR', label: 'Brazil' },
    { value: 'CL', label: 'Chile' },
    { value: 'CO', label: 'Colombia' },
    { value: 'MX', label: 'Mexico' },
    { value: 'US', label: 'United States' },
  ],
};

type ConfigType<T> = Record<MultiDropdownOptions, MultiDropdownOptionConfig<T>>;

export const MULTI_DROPDOWN_OPTIONS_CONFIG: ConfigType<Get_BrandsQuery & Get_AgenciesQuery & Get_Industries_V5Query> = {
  [MultiDropdownOptions.Brands]: {
    schema: GET_BRANDS,
    queryName: 'brands',
    queryOptions: { skip: true },
    convertFetchedData: (data) => convertQueryResultToDropdownOptions<Brand>(data?.brands),
    queryPageInfo: (data) => data?.brands?.pageInfo!,
    queryOffset: (data) => data?.brands?.edges.length!,
  },
  [MultiDropdownOptions.Agencies]: {
    schema: GET_AGENCIES,
    queryName: 'getAgencies',
    queryOptions: { skip: true },
    additionalQueryVariables: { crmLinkTypeList: [CrmLinkTypeV5.SfdcAdAccount] },
    convertFetchedData: (data) => convertQueryResultToDropdownOptions<Agency>(data?.getAgencies),
    queryPageInfo: (data) => data?.getAgencies?.pageInfo!,
    queryOffset: (data) => data?.getAgencies?.edges.length!,
  },
  [MultiDropdownOptions.Industries]: {
    schema: GET_INDUSTRIES_V5,
    queryName: 'getIndustriesV5',
    queryOptions: { variables: { searchTerm: '', offset: DEFAULT_FILTER_OFFSET, limit: DEFAULT_FILTER_LIMIT } },
    convertFetchedData: (data) => convertQueryResultToDropdownOptions<Industry>(data?.getIndustriesV5),
    queryPageInfo: (data) => data?.getIndustriesV5?.pageInfo!,
    queryOffset: (data) => data?.getIndustriesV5?.edges.length!,
  },
};

export const BILLED_DROPDOWN_OPTION = { label: '1P-Billed', value: '1P-Billed' };
