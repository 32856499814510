import './AssetPreview.scss';

import React from 'react';
import ReactPlayer from 'react-player';

import { SPReviewStatus } from '../_ForCommonLibrary/components/atoms/AssetStatusPill/constants';
import { useAsset } from '../../hooks/useAsset';
import type { AssetV5 } from '../../models';
import bem from '../../utils/bem';
import { titleCase } from '../../utils/formatting';
import useGetAssetById from '../AdForm/CreativeBuilders/hooks/useGetAssetById';
import useTranscodingFailure from '../AdForm/CreativeBuilders/hooks/useTranscodingFailure';
import AssetDetails from '../AssetDetails';
import Loader from '../Loader';
import Pill, { PillColor } from '../Pill';
import { getAcceptableUrl, getColor, getDuration, getPreviewUrl } from './util';

export interface AssetPreviewProps {
  asset: Partial<AssetV5>;
  creativeId?: string;
}

const [block, element] = bem('asset-preview');

function AssetPreview({ asset, creativeId }: AssetPreviewProps): JSX.Element {
  const { asset: assetDetails, hasReviewTaskId, loading, spReview } = useGetAssetById(asset.id!, creativeId);

  const transcodeStatus =
    assetDetails?.transcodeStatus && asset.type === 'VIDEO' ? assetDetails.transcodeStatus : undefined;

  const { message, name: transcodingFailureName } = useTranscodingFailure({
    assetId: asset.id,
    transcodeStatus,
  });

  const videoType = asset.type === 'VIDEO';

  const { assetUrl } = useAsset(getPreviewUrl(asset.id));

  const lockedSPReview = spReview === SPReviewStatus.LOCKED;
  const availableReview = assetDetails?.review || asset.review;

  const renderVideoAsset = (
    <div className={element('video-container')}>
      {!assetUrl ? (
        <>
          <div className={element('loading-content')}>
            <Loader />
          </div>
          <video controls src={''} aria-label="video-player" />
        </>
      ) : (
        <ReactPlayer url={assetUrl} controls={true} width={`100%`} height={`100%`} />
      )}
    </div>
  );

  const renderImageAsset = (
    <div className={element('image-container')}>
      {!assetUrl ? <Loader /> : <img alt={assetDetails?.name} src={assetUrl} />}
    </div>
  );

  return (
    <div className={block()}>
      {lockedSPReview && (
        <Pill className={element('review-status-pill')} text={'Under S&P Review'} color={PillColor.ORANGE} />
      )}
      {availableReview && !lockedSPReview && (
        <Pill
          className={element('review-status-pill')}
          text={titleCase(availableReview)}
          color={getColor(availableReview)}
        />
      )}
      {asset.type === 'DISPLAY' && renderImageAsset}
      {videoType && renderVideoAsset}

      <AssetDetails
        assetDetailsUrl={getAcceptableUrl(loading, creativeId, asset.id, hasReviewTaskId)}
        assetTagList={assetDetails?.assetTagList ?? []}
        createdAt={assetDetails?.createdAt ? new Date(assetDetails?.createdAt) : new Date()}
        filename={assetDetails?.name}
        fileSize={assetDetails?.fileSize || 0}
        fileUrl={`/api/asset-download/${asset.id}`}
        review={asset.review || assetDetails?.review || ''}
        transcodeStatus={transcodeStatus}
        transcodingDetails={{ name: transcodingFailureName, message }}
        videoDuration={getDuration(asset.type, asset.type === 'VIDEO' ? asset.duration : undefined)}
        type={asset.type}
      />
    </div>
  );
}

export default AssetPreview;
