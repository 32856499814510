import type { CreativeMetadata } from '../../../../../apis/graphql';
import { campaignClient } from '../../../../../apis/graphql';
import { GET_CREATIVE_METADATA_BY_CREATIVE_ID } from '../../queries/getCreativeMetadataByCreativeId';

export async function fetchCreativeMetadata(
  creativeId?: string,
  disableCache = false
): Promise<CreativeMetadata | null> {
  if (!creativeId) return null;

  const { data } = await campaignClient.query({
    query: GET_CREATIVE_METADATA_BY_CREATIVE_ID,
    variables: { creativeId },
    fetchPolicy: disableCache ? undefined : 'no-cache',
  });

  return data?.getCreativeMetadataByCreativeIdV5 ?? null;
}
