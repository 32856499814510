import type { OrderFilter, TraffickingFilters, UserFilter } from '../../apis/graphql';
import { isAllSelectedValue } from '../../common/MultiSelectDropdown';
import { TraffickingTableName } from '../../constants';
import type { Nullable } from '../../models';
import { BASIC_FILTERS_QUERY_VARIABLES, QueryVariablesKeys } from './constants';
import { ALL_TIME_DATE_KEY } from './TableFilterBar/DateFilter';
import type { SelectedDateValue } from './TableFilterBar/DateFilter/helpers';
import type { QueryVariables, SelectedRowIds } from './TraffickingPage/hooks';
import { DEFAULT_LIMIT } from './TraffickingPage/hooks';
import { isValidSortOption, sortLineItemStatusesAndSubStatuses } from './TraffickingPage/hooks/utils';

export const getSelectedRowIdsFromState = (selectedRowsIds: string[] = []): Record<string, boolean> =>
  selectedRowsIds.reduce(
    (acc, id) => ({
      ...acc,
      [id]: true,
    }),
    {}
  );

export type GenerateQueryVariablesOptions<T extends TraffickingTableName> = {
  searchTerm: string;
  sortOption: NonNullable<TraffickingFilters[T]>['sortBy'] | undefined;
  accountManager: Nullable<UserFilter[]>;
  accountExecutive: Nullable<UserFilter[]>;
  dateFilter: SelectedDateValue;
  productType: Nullable<string[]>;
  pacingStatusList: Nullable<string[]>;
  reviewStatusList: Nullable<string[]>;
  pacingRiskList: Nullable<string[]>;
  hasMisalignedSchedule: Nullable<boolean>;
  adStatusList: Nullable<string[]>;
  orderTypeList: Nullable<string[]>;
  tierNameList: Nullable<string[]>;
  priorityValueList: Nullable<string[]>;
  billableThirdPartyList: Nullable<string[]>;
  publisherList: Nullable<string[]>;
  isCoppaOrCaru: Nullable<string[]>;
  isMakegood: Nullable<string[]>;
  isAddedValue: Nullable<string[]>;
  lineItemStatusList: Nullable<string[]>;
  adRatingList: Nullable<string[]>;
  adTypeList: Nullable<string[]>;
  creativeTypeList: Nullable<string[]>;
  creativeReviewList: Nullable<string[]>;
  brandIdList: Nullable<OrderFilter[]>;
  industryIdList: Nullable<OrderFilter[]>;
  agencyIdList: Nullable<OrderFilter[]>;
  currencyList: Nullable<string[]>;
  countryList: Nullable<string[]>;
  isRotation: Nullable<string[]>;
  isSequence: Nullable<string[]>;
  tableName: T;
  selectedRowIds: SelectedRowIds;
  campaignRowsIds?: SelectedRowIds;
  lineItemRowIds?: SelectedRowIds;
};

export const convertStringToBoolean = (values: string[]): boolean | null => {
  if (values.includes('Yes')) {
    return true;
  }

  if (values.includes('No')) {
    return false;
  }

  return null;
};

export const getOrderFilterQueryVariables = (
  filter: Nullable<OrderFilter[]>,
  key: QueryVariablesKeys
): { [x: string]: string[] } => {
  if (!!filter?.length) {
    return { [key]: filter.map(({ value }) => value) };
  }

  return {};
};

export const getBooleanFilterQueryVariables = (
  filter: Nullable<string[]>,
  key: QueryVariablesKeys
): { [x: string]: boolean | null } => {
  if (!!filter?.length) {
    return { [key]: convertStringToBoolean(filter) };
  }

  return {};
};

export const getUserFilterQueryVariables = (
  filter: Nullable<UserFilter[]>,
  key: QueryVariablesKeys
): { [x: string]: string[] } => {
  if (!!filter?.length && !isAllSelectedValue(filter)) {
    return { [key]: filter.map(({ value }) => value) };
  }

  return {};
};

export const generateQueryVariables = <T extends TraffickingTableName>(
  options: GenerateQueryVariablesOptions<T>,
  limit: number = DEFAULT_LIMIT,
  offset: number = 0
): QueryVariables => {
  const {
    sortOption,
    accountManager,
    accountExecutive,
    dateFilter,
    hasMisalignedSchedule,
    lineItemStatusList,
    tableName,
    selectedRowIds,
    campaignRowsIds,
    lineItemRowIds,
    searchTerm,
    productType,
    isAddedValue,
    isCoppaOrCaru,
    isMakegood,
    isRotation,
    isSequence,
    brandIdList,
    industryIdList,
    agencyIdList,
    ...filterOptions
  } = options;

  const queryVariables: QueryVariables = {
    limit,
    offset,
    [QueryVariablesKeys.ids]: Object.keys(selectedRowIds || {}),
  };

  if (tableName === TraffickingTableName.lineItems) {
    queryVariables[QueryVariablesKeys.campaignIds] = Object.keys(campaignRowsIds || {});
  } else if (tableName === TraffickingTableName.ads) {
    queryVariables[QueryVariablesKeys.campaignIds] = Object.keys(campaignRowsIds || {});
    queryVariables[QueryVariablesKeys.lineItemIds] = Object.keys(lineItemRowIds || {});
  }

  if (productType && productType.length) {
    queryVariables.adProductList = productType;
  }

  if (lineItemStatusList && lineItemStatusList.length) {
    const { filteredStatuses, filteredSubStatuses } = sortLineItemStatusesAndSubStatuses(lineItemStatusList);
    if (tableName === TraffickingTableName.lineItems) {
      queryVariables.statusList = filteredStatuses;
      queryVariables.subStatusList = filteredSubStatuses;
    } else {
      queryVariables.lineItemStatusList = filteredStatuses;
      queryVariables.lineItemSubStatusList = filteredSubStatuses;
    }
  }

  // if the date filter is not set to 'All Time'
  if (dateFilter && dateFilter.label !== ALL_TIME_DATE_KEY) {
    queryVariables[QueryVariablesKeys.schedule] = dateFilter?.value;
  }

  if (hasMisalignedSchedule) {
    queryVariables.hasMisalignedSchedule = hasMisalignedSchedule;
  }

  // if there is a sort option selected and it is valid
  if (isValidSortOption(tableName, sortOption)) {
    queryVariables[QueryVariablesKeys.sort] = sortOption.sort;
    queryVariables[QueryVariablesKeys.order] = sortOption.order;
  }

  // if there is a search term
  if (searchTerm && searchTerm.length > 0) {
    queryVariables[QueryVariablesKeys.searchTerm] = searchTerm.toLowerCase();
  }

  const orderFilters = {
    ...getOrderFilterQueryVariables(brandIdList, QueryVariablesKeys.brandIdList),
    ...getOrderFilterQueryVariables(agencyIdList, QueryVariablesKeys.agencyIdList),
    ...getOrderFilterQueryVariables(industryIdList, QueryVariablesKeys.industryIdList),
  };

  const booleanFilters = {
    ...getBooleanFilterQueryVariables(isAddedValue, QueryVariablesKeys.isAddedValue),
    ...getBooleanFilterQueryVariables(isCoppaOrCaru, QueryVariablesKeys.isCoppaOrCaru),
    ...getBooleanFilterQueryVariables(isMakegood, QueryVariablesKeys.isMakegood),
    ...getBooleanFilterQueryVariables(isRotation, QueryVariablesKeys.isRotation),
    ...getBooleanFilterQueryVariables(isSequence, QueryVariablesKeys.isSequence),
  };

  const userFilters = {
    ...getUserFilterQueryVariables(accountManager, QueryVariablesKeys.traffickerEmailList),
    ...getUserFilterQueryVariables(accountExecutive, QueryVariablesKeys.accountExecutiveList),
  };

  (BASIC_FILTERS_QUERY_VARIABLES as Array<keyof typeof filterOptions>).forEach((key) => {
    const value = filterOptions[key];

    if (value && value.length) {
      queryVariables[key] = value;
    }
  });

  return { ...queryVariables, ...orderFilters, ...booleanFilters, ...userFilters };
};
