import { useQuery } from '@apollo/client';

import type { CreativeMetadata, Query } from '../../../../apis/graphql';
import { GET_CREATIVE_METADATA_BY_CREATIVE_ID } from '../../../../pages/Ads/hooks/queries/getCreativeMetadataByCreativeId';

export const useCreativeMetadataByCreativeId = (
  creativeId: string,
  disableCache = false
): { metadataLoading: boolean; creativeMetadata: CreativeMetadata | null } => {
  const { loading, data } = useQuery<Query>(GET_CREATIVE_METADATA_BY_CREATIVE_ID, {
    variables: { creativeId },
    skip: !creativeId,
    fetchPolicy: disableCache ? 'no-cache' : undefined,
  });

  return { metadataLoading: loading, creativeMetadata: data?.getCreativeMetadataByCreativeIdV5 ?? null };
};
