import type { DeselectOptionActionMeta, SelectOptionActionMeta } from 'react-select';

import type { Nullable, TargetingTermValue } from '../../models';
import type { TargetingValueOption } from '../AdForm/adFormik';
import type { DropdownOption } from '../Dropdown';
import { ActionTypes, isClearAllOption } from '../MultiSelectDropdown';
import type { MultiSelectTargetingValue } from './types';

export const getEmptyTextMessage = (loading: boolean, requiredEntry: Nullable<DropdownOption>): string => {
  if (!requiredEntry) return 'Select a category and type first';

  return loading ? 'Loading...' : 'Select';
};

export const formatOptionsToDropdownOptions = (options: MultiSelectTargetingValue): DropdownOption[] =>
  options.map(({ id, value, label, tooltip, description }) => ({
    id,
    value,
    label,
    ...(tooltip && { tooltip }),
    ...(description && { description }),
  }));

export const formatOptionsToTargetingValueOptions = (
  options: MultiSelectTargetingValue,
  selectedDimension: DropdownOption,
  selectedValues: TargetingValueOption[]
): TargetingValueOption[] =>
  selectedDimension.id
    ? options.map(
        ({ id, value, label, tooltip, description }) =>
          ({
            id,
            value,
            label,
            dimension: selectedDimension.id,
            ...(tooltip && { tooltip }),
            ...(description && { description }),
          } as TargetingValueOption)
      )
    : selectedValues;

export const getUnselectedValues = (
  targeting: Pick<TargetingTermValue, 'dimension' | 'value'>[],
  selectedDimension: Nullable<DropdownOption>,
  selectedValues: TargetingValueOption[]
): string[] =>
  targeting.reduce<string[]>((unselected, { dimension, value }) => {
    const isDimensionsEqual = dimension.id === selectedDimension?.id;
    const isValueSelected = selectedValues.find((selected) => selected.value === value?.name);

    if (isDimensionsEqual && !isValueSelected) return [...unselected, value.name];

    return unselected;
  }, []);

export const getCurrentSelectedValues = (
  currentSelected: DropdownOption[],
  meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>
): DropdownOption[] => {
  if (isClearAllOption(meta)) return [];

  return meta.action === ActionTypes.select
    ? [...currentSelected, meta.option!]
    : currentSelected.filter(({ value }) => value !== meta.option?.value);
};

export const getSelectedValues = (
  selectedValues: TargetingValueOption[],
  meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>,
  selectedDimension: Nullable<DropdownOption>
): TargetingValueOption[] => {
  if (isClearAllOption(meta)) return selectedValues.filter(({ dimension }) => dimension !== selectedDimension?.id);

  if (meta.action === ActionTypes.select) {
    if (!selectedDimension?.id) return selectedValues;

    return [...selectedValues, { ...meta.option!, dimension: selectedDimension.id }];
  }

  return selectedValues.filter(({ value }) => value !== meta.option?.value);
};

export const hasValuesChanged = (newValues: TargetingValueOption[], selectedValues: TargetingValueOption[]): boolean =>
  newValues.length !== selectedValues.length || !newValues.every((v, i) => v.value === selectedValues[i].value);

export const getNoOptionsMessage = (searching: boolean, supportEmptySearch: boolean, searchTerm: string): string => {
  if (searching) return 'Loading';

  return supportEmptySearch || searchTerm ? 'No Options' : 'Start typing to find options';
};
