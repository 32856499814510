import { IconChevronRight } from '@hulu-react-style-components/icons';
import React from 'react';
import type { Row } from 'react-table';
import { CSSTransition } from 'react-transition-group';

import type { TargetingTermValue } from '../../../models';
import { DIMENSION_INCLUDE_EXCLUDE_LIST } from '../../TargetingDropdowns/constants';

interface GroupedRowData {
  key: string;
  items: Row<TargetingTermValue>[];
  category: string;
  dimension: string;
  include: boolean;
}

type ElementFunction = (baseClass: string, modifier?: string) => string;

export const buildRowGroups = (rows: Row<TargetingTermValue>[]): { [key: string]: GroupedRowData } => {
  const groupedRowData = rows.reduce((acc, row) => {
    const dimensionKey = row.original.dimension.id;
    const key = DIMENSION_INCLUDE_EXCLUDE_LIST.includes(dimensionKey)
      ? `${row.original.category.displayName}-${row.original.dimension.displayName}-${row.original.include}`
      : `${row.original.category.displayName}-${row.original.dimension.displayName}`;

    if (!acc[key]) {
      acc[key] = {
        key,
        items: [],
        category: row.original.category.displayName,
        dimension: row.original.dimension.displayName,
        include: row.original.include,
      };
    }

    acc[key].items.push(row);

    return acc;
  }, {} as { [key: string]: GroupedRowData });

  return groupedRowData;
};

export const generateRow = (
  termValueRow: Row<TargetingTermValue>,
  rowIndex: number,
  groupType: string | undefined,
  element: ElementFunction,
  groupLength?: number,
  groupExpanded?: boolean,
  toggle?: () => void
): React.JSX.Element => {
  return (
    <table className={element('sub-row-table')} key={`sub-table-${rowIndex}`}>
      <tbody className={element('sub-row-body')} key={`sub-body-${rowIndex}`}>
        <tr {...termValueRow.getRowProps()} key={`sub-row-${rowIndex}`}>
          {termValueRow.cells.map((cell, colIndex) => {
            return (
              <td {...cell.getCellProps()} tabIndex={-1}>
                {cell.render('Cell', {
                  isLineItemTargetingTermValue: groupType === 'Line Item Targeting',
                })}
                {groupLength !== undefined && !groupExpanded && rowIndex === 0 && colIndex === 2 && (
                  <button className="expand-row-link" onClick={toggle}>
                    (+{groupLength} more)
                  </button>
                )}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export const generateToggleRow = (
  rowGroup: React.JSX.Element[],
  groupExpanded: boolean,
  element: ElementFunction,
  toggle: () => void
): React.JSX.Element => {
  return (
    <div className={element('toggle-row')}>
      <div className={element('toggle-top-row', groupExpanded ? 'active' : '')}>
        <div className={element('toggle-top-row-wrapper')}>
          <IconChevronRight className="chevron" onClick={toggle} />
          <div className={element('toggle-row-first-child')}>{rowGroup[0]}</div>
        </div>
      </div>
      <CSSTransition classNames={element('toggle')} in={groupExpanded} timeout={150} unmountOnExit appear>
        <div className={element('toggle-row-children')}>{rowGroup.slice(1)}</div>
      </CSSTransition>
    </div>
  );
};

export const getExpandCollapseText = (expandedGroups: Record<string, boolean>): string => {
  const allExpanded =
    Object.values(expandedGroups).every((isExpanded) => isExpanded === true) && Object.keys(expandedGroups).length > 0;

  return allExpanded ? 'Collapse All' : 'Expand All';
};
