import type { ModalProps } from 'antd';
import React from 'react';

import { SubmitInput } from '..';
import PreviewWrapper from '../PreviewWrapper';
import type { SubmitInputProps } from '../SubmitInput';

export interface TagInputProps extends Pick<SubmitInputProps, 'modalProps' | 'modalMessage' | 'style' | 'disabled'> {
  url: string;
  isSubmitted: boolean;
  setUrl: (url: string) => void;
  clearTag: () => void;
  submitTag: () => void;
  isPreview?: boolean;
  label?: string;
  secondaryLabel?: string;
  errMsg?: string;
  customInputComponent?: React.JSX.Element | null;
}

const defaultModalProps: ModalProps = {
  cancelText: 'NO, DO NOT REMOVE',
  okText: 'YES, REMOVE TAG',
  title: 'Remove VAST Tag',
};
const defaultModalMessage = 'Are you sure you want to remove VAST Tag from this ad?';

export const DEFAULT_TAG_INPUT_LABEL = 'VAST/VPAID Tag';

function TagInput({
  url,
  isSubmitted,
  setUrl,
  submitTag,
  clearTag,
  isPreview,
  label = DEFAULT_TAG_INPUT_LABEL,
  secondaryLabel,
  errMsg,
  customInputComponent,
  ...props
}: TagInputProps): JSX.Element {
  return (
    <PreviewWrapper label={label} value={url} isPreview={isPreview}>
      <SubmitInput
        {...props}
        label={label}
        secondaryLabel={secondaryLabel}
        errMsg={errMsg}
        value={url}
        onInput={setUrl}
        onSubmit={submitTag}
        onClear={clearTag}
        modalProps={defaultModalProps}
        modalMessage={defaultModalMessage}
        inputSubmitted={isSubmitted}
        loading={false}
        customInputComponent={customInputComponent}
      />
    </PreviewWrapper>
  );
}

export default TagInput;
