import gql from 'graphql-tag';

export const GET_TRAFFICKING_FILTERS = gql`
  query GET_TRAFFICKING_FILTERS($shareableId: String!) {
    getTraffickingFilters(shareableId: $shareableId) {
      productType
      dateFilter {
        label
        value {
          end {
            day
            hour
            month
            year
          }
          start {
            day
            hour
            month
            year
          }
          status
          timezone
        }
      }
      accountManager {
        label
        value
      }
      accountExecutive {
        label
        value
      }
      pacingRiskList
      hasMisalignedSchedule
      pacingStatusList
      reviewStatusList
      adStatusList
      orderTypeList
      lineItemStatusList
      tierNameList
      priorityValueList
      billableThirdPartyList
      publisherList
      adRatingList
      adTypeList
      creativeTypeList
      creativeReviewList
      currencyList
      isCoppaOrCaru
      isMakegood
      isAddedValue
      brandIdList {
        label
        value
      }
      industryIdList {
        label
        value
      }
      agencyIdList {
        label
        value
      }
      isRotation
      isSequence
      selectedTab
      countryList
      campaigns {
        searchTerm
        selectedRowIds
        sortBy {
          sort
          order
        }
      }
      lineItems {
        searchTerm
        selectedRowIds
        sortBy {
          sort
          order
        }
      }
      ads {
        searchTerm
        selectedRowIds
        sortBy {
          sort
          order
        }
      }
    }
  }
`;
