import './TargetingDropdowns.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DeselectOptionActionMeta, SelectOptionActionMeta } from 'react-select';

import Button from '../../common/Button';
import Dropdown from '../../common/Dropdown';
import type { TargetingCategory, TargetingDimension, TargetingTermValue, TargetingValue } from '../../models';
import bem from '../../utils/bem';
import type { ListHelpers } from '../../utils/listHelpers';
import type { TargetingValueOption } from '../AdForm/adFormik';
import type { DropdownOption } from '../Dropdown';
import { DIMENSION_INCLUDE_EXCLUDE_LIST } from './constants';
import { useTargetingDropdowns } from './hooks';
import type { TargetingDropdownOption } from './hooks/useTargetingDropdowns';
import { createDropdownOptionFromTargeting } from './hooks/utils';
import TargetingValuesDropdown from './TargetingValuesDropdown';
import type { MultiSelectTargetingValue } from './types';
import {
  formatOptionsToDropdownOptions,
  formatOptionsToTargetingValueOptions,
  getCurrentSelectedValues,
  getEmptyTextMessage,
  getSelectedValues,
  getUnselectedValues,
  hasValuesChanged,
} from './utils';

export interface TargetingDropdownProps {
  /**
   * The listHelpers prop is a set of functions to help keep the list store up to date.
   *
   * We use insertAt for adding new items to the list that is selected, and replaceAt will replace the record
   * at a specific index to update that record when using the toggles.
   * And removeAt for remove specific targeting from targetingTable
   */
  listHelpers: Pick<ListHelpers<TargetingTermValue>, 'insertAt' | 'replaceAt' | 'removeAt'>;
  /** A boolean flag to enable a select all option in the dropdown. */
  readonly?: boolean;
  /** An array of TargetingTermValues that represent what has been added to the Targeting Table. */
  targetings: TargetingTermValue[];
  /** Validation error message if any */
  validationError?: string;
  countryOption?: TargetingTermValue;
  publisherOption?: TargetingValue;
}

const [block, element] = bem('targeting-dropdowns');

/**
 * A ReactJS component for handling targeting rules for Ads and LineItems.
 * @param insertAt Function A function to handle adding new items to the list store for this specific list.
 * @param replaceAt Function A function to handle replacement of items in a current list store for this specific list.
 * @param removeAt Function A function to handle remove of item in a current list store for this specific list.
 * @param isAd boolean A boolean flag to let the component know what list to adjust.
 * @constructor
 */
const TargetingDropdowns = ({
  listHelpers,
  readonly,
  targetings,
  validationError,
  countryOption,
  publisherOption,
}: TargetingDropdownProps): React.JSX.Element => {
  const filteredTargetings = useMemo(
    () =>
      targetings.filter(
        (targeting) => targeting.category !== null && targeting.dimension !== null && targeting.value !== null
      ),
    [targetings]
  );

  const {
    categoryOptions,
    selectedCategory,
    setSelectedCategory,
    isLoadingCategories,
    dimensionsOptions,
    selectedDimension,
    setSelectedDimension,
    isLoadingDimensionsByCategory,
    errorGettingDimensionsByCategory,
    selectedValues,
    setSelectedValues,
    clearAll,
  } = useTargetingDropdowns();

  const [currentSelectedValues, setCurrentSelectedValues] = useState<TargetingValueOption[]>([]);
  const previousSelectedDimension = useRef(selectedDimension);

  const onClearFormStateAndSelectedValues = (): void => {
    setCurrentSelectedValues([]);

    clearAll();
  };

  /**
   * A function to handle replacing a value in the list store at a specific index for this instance.
   * @param items TargetingTermValue[] A list of TargetingTermValues that will be used to determine when to do a replace.
   * @param category TargetingCategory A TargetingCategory to be used when doing the replace procedure.
   * @param dimension TargetinDimension A TargetingDimension to be used when doing the replace procedure.
   * @param isInclude boolean A boolean flag to represent if the item being replaced is to be included or excluded.
   */
  const doReplaceAt = (
    items: TargetingTermValue[],
    category: TargetingCategory,
    dimension: TargetingDimension,
    isInclude: boolean
  ): void => {
    items.forEach((item, index) => {
      if (item.dimension.name === selectedDimension?.value) {
        listHelpers.replaceAt(index, {
          category,
          dimension,
          value: { ...item.value },
          include: isInclude,
        });
      }
    });
  };

  /**
   * A function to handle inserting new rows to into the list store.
   * @param isInclude boolean A flag to represent if an item being added in included or excluded.
   */
  const handleInsert = (isInclude: boolean): void => {
    if (selectedCategory && selectedDimension && selectedValues.length) {
      const category = {
        id: selectedCategory.id || '',
        name: selectedCategory.value,
        displayName: selectedCategory.label,
      };

      const dimension = {
        id: selectedDimension.id || '',
        name: selectedDimension.value,
        displayName: selectedDimension.label,
      };

      const itemsToReplace = [...filteredTargetings];

      currentSelectedValues.forEach((value: TargetingValueOption) => {
        const alreadyThere = itemsToReplace.find(
          ({ category: { name: categoryName }, dimension: { name: dimensionName }, value: { name: valueName } }) =>
            categoryName === selectedCategory.value &&
            dimensionName === selectedDimension.value &&
            valueName === value.value
        );

        if (!alreadyThere) {
          const insertObj = {
            category,
            dimension,
            value: {
              id: value.id || '',
              name: value.value,
              displayName: value.label,
              ...(value?.description && { description: value.description }),
              ...(value?.tooltip && { tooltip: value.tooltip }),
            },
            include: isInclude,
          };
          listHelpers.insertAt(0, insertObj);
          itemsToReplace.unshift(insertObj);
        }
      });

      if (selectedDimension.id && !DIMENSION_INCLUDE_EXCLUDE_LIST.includes(selectedDimension.id)) {
        doReplaceAt(itemsToReplace, category, dimension, isInclude);
      }
    }

    onClearFormStateAndSelectedValues();
  };

  const handleSelectValues = (
    options: MultiSelectTargetingValue,
    meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>
  ): void => {
    const newValues = getSelectedValues(selectedValues, meta, selectedDimension);

    setCurrentSelectedValues((current) => getCurrentSelectedValues(current, meta));

    if (hasValuesChanged(newValues, selectedValues)) setSelectedValues(newValues);
  };

  const handleClearAll = useCallback((): void => {
    setCurrentSelectedValues([]);
    setSelectedValues([]);
  }, [setCurrentSelectedValues, setSelectedValues]);

  const handleSelectAll = useCallback(
    (options: MultiSelectTargetingValue): void => {
      if (selectedDimension) {
        setCurrentSelectedValues(formatOptionsToDropdownOptions(options));
        setSelectedValues(formatOptionsToTargetingValueOptions(options, selectedDimension, selectedValues));
      }
    },
    [selectedDimension, selectedValues, setSelectedValues]
  );

  const selectedDimensionValues = useMemo(() => {
    return selectedValues?.length ? selectedValues.filter(({ dimension }) => dimension === selectedDimension?.id) : [];
  }, [selectedDimension, selectedValues]);

  // used to avoid mixing selectedValues with incorrect dimensions
  useEffect(() => {
    if (previousSelectedDimension.current?.id !== selectedDimension?.id) {
      previousSelectedDimension.current = selectedDimension;
      handleClearAll();
    }
  }, [handleClearAll, selectedDimension]);

  // used to set pre-selected values to selectedValues, if there are not set
  useEffect(() => {
    if (selectedDimension?.id) {
      if (selectedValues.length > 0) {
        return;
      }

      setSelectedValues(
        filteredTargetings.reduce<TargetingValueOption[]>(
          (options, targetingTableValue) =>
            targetingTableValue.value?.name
              ? [...options, createDropdownOptionFromTargeting(targetingTableValue)]
              : options,
          []
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimension]);

  const onClose = (): void => {
    const unselectedValues = getUnselectedValues(filteredTargetings, selectedDimension, selectedValues);

    unselectedValues.forEach((elementThatShouldRemoved: string, index: number) => {
      const targetingIndexToDelete = filteredTargetings.findIndex(
        (el: TargetingTermValue) => el.value?.name === elementThatShouldRemoved
      );

      listHelpers.removeAt(targetingIndexToDelete - index);
    });
  };

  return (
    <div className={block()}>
      <div className={element('category-dimension-wrapper')}>
        <div className={element('category-dropdown')}>
          <Dropdown
            value={selectedCategory}
            label="Category"
            errMsg={validationError}
            options={categoryOptions}
            isDisabled={isLoadingCategories || readonly}
            onChange={(value: DropdownOption | null): void =>
              setSelectedCategory({ id: value?.value, ...value } as TargetingDropdownOption)
            }
            dataTestId="targeting-category-dropdown"
          />
        </div>
        <div className={element('dimension-wrapper')}>
          <Dropdown
            value={selectedDimension}
            // While the data is referred to as a 'dimension' the UI labels it as a 'Type'
            label="Type"
            errMsg={validationError}
            options={dimensionsOptions || []}
            isLoading={isLoadingDimensionsByCategory}
            error={errorGettingDimensionsByCategory?.message}
            isDisabled={!selectedCategory || isLoadingDimensionsByCategory || readonly}
            emptyDisplayText={getEmptyTextMessage(isLoadingDimensionsByCategory, selectedCategory)}
            onChange={(value: DropdownOption | null): void => {
              if (value === null) setSelectedDimension(null);
              setSelectedDimension({ id: value?.value || null, ...value } as TargetingDropdownOption);
            }}
            dataTestId="targeting-dimension-dropdown"
          />
        </div>
      </div>
      <div className={element('value-wrapper')}>
        <TargetingValuesDropdown
          handleSelect={handleSelectValues}
          selectedValues={selectedDimensionValues}
          handleSelectAll={handleSelectAll}
          handleClearAll={handleClearAll}
          selectedDimension={selectedDimension}
          onClose={onClose}
          errMsg={validationError}
          readonly={readonly}
          dataTestId="targeting-values-dropdown"
          countryOption={countryOption}
          publisherOption={publisherOption}
        />
      </div>
      <div className={element('action-wrapper')}>
        <button
          disabled={readonly}
          className={element('clear')}
          title="Clear Selections"
          onClick={onClearFormStateAndSelectedValues}
        >
          Clear Selections
        </button>
        <div>
          <Button
            icon="IconSlash"
            size="medium"
            className={currentSelectedValues.length ? 'exclude-btn' : ''}
            onClick={(): void => handleInsert(false)}
            title="Exclude"
            disabled={!currentSelectedValues.length || readonly}
          >
            Exclude {currentSelectedValues?.length > 0 ? currentSelectedValues?.length : ''}
          </Button>
          <Button
            icon="IconCheck"
            size="medium"
            className={currentSelectedValues.length ? 'include-btn' : ''}
            onClick={(): void => handleInsert(true)}
            title="Include"
            disabled={!currentSelectedValues.length || readonly}
          >
            Include {currentSelectedValues?.length > 0 ? currentSelectedValues?.length : ''}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TargetingDropdowns;
