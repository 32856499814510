import { IconShare } from '@hulu-react-style-components/icons';
import { AssetDownloadButton } from 'mission-control-common-components';
import React from 'react';

import type { SpReviewV5, VideoAssetV5 } from '../../../../../apis/graphql';
import { stringifyTagList } from '../../../../../utils/formatting';
import { resourcePaths } from '../../../constants';
import type { AssetExtended } from '../../../types/extended';
import { bytesToLabel, msToTime } from '../../../util/formatters';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { StatusModalWithPill } from './atoms/StatusModalWithPill/StatusModalWithPill';
import { Card, FileData, FileDetails, FileDownload, FileName, IconAnchor, MetaDataTable, Preview } from './styles';

export const AssetPreviewCard: React.FC<{
  asset: AssetExtended;
  creativeId: string;
  spReview?: SpReviewV5 | null;
}> = ({ asset, creativeId }) => {
  const { type, review, s3Url, filename, fileSize, id, createdAt, updatedAt, assetTagList } = asset;

  const isVideo = type === 'VIDEO';

  const downloadUrl = `/api/asset-download/${id}`;
  const downloadFilename = filename || '';
  const acceptableUrl =
    creativeId && id ? `${resourcePaths.ASSET_REVIEW_ASSET}?creative-id=${creativeId}&asset-id=${id}` : '';

  return (
    <Card published={review === 'PUBLISHED'}>
      {isVideo ? (
        <VideoPlayer asset={asset as VideoAssetV5}>
          <StatusModalWithPill asset={asset} />
        </VideoPlayer>
      ) : (
        <Preview imageUrl={s3Url}>
          <StatusModalWithPill asset={asset} />
        </Preview>
      )}
      <FileData>
        <FileName>{isVideo && filename}</FileName>
        <FileDetails style={{ display: 'flex' }}>
          {isVideo && (
            <>
              <span>{msToTime((asset as VideoAssetV5).duration)}</span>
              <span>{bytesToLabel(fileSize)}</span>
            </>
          )}
          <FileDownload>
            <AssetDownloadButton filename={downloadFilename} url={downloadUrl} />
          </FileDownload>
        </FileDetails>
      </FileData>
      <MetaDataTable>
        <tbody>
          <tr>
            <th>ID</th>
            <td>
              {id}{' '}
              {acceptableUrl && (
                <IconAnchor href={acceptableUrl} target="_blank">
                  <IconShare />
                </IconAnchor>
              )}
            </td>
          </tr>
          <tr>
            <th>Created</th>
            <td>{createdAt}</td>
          </tr>
          <tr>
            <th>Last Seen</th>
            <td>{updatedAt}</td>
          </tr>
          <tr>
            <th>Asset Tag</th>
            <td>{stringifyTagList(assetTagList ?? [])}</td>
          </tr>
        </tbody>
      </MetaDataTable>
    </Card>
  );
};
