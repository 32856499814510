import type { AdModel, LineItemModel } from '../../../../../TraffickingPage/modelConverters';
import type {
  MultipleEditDrawerAdsFormValues,
  MultipleEditDrawerFormValues,
  MultipleEditDrawerLineItemsFormValues,
} from '../../../types';

type Props = {
  values: MultipleEditDrawerFormValues;
  isLineItem: boolean;
};

type Entity = {
  id: string;
  status?: LineItemModel['status'] | LineItemModel['subStatus'] | AdModel['status'] | null;
  isSelected: boolean;
};

type FormValues<T extends Entity> = {
  entities: T[];
  initialStatuses: Entity[];
  selectedSections: string[];
};

type FormValuesWithoutSelectedSections<T extends Entity> = Omit<FormValues<T>, 'selectedSections'>;

const getIsStatusChanged = <T extends Entity>(values: FormValues<T>): boolean => {
  const hasEntityWithChangedStatus = values.entities?.some((entity) => {
    const initialStatus = values.initialStatuses.find((initialEntity) => initialEntity.id === entity.id)?.status?.key;

    return initialStatus !== entity.status?.key;
  });

  const isStatusSectionSelected = !!values.selectedSections.includes('Status');

  return isStatusSectionSelected && !!hasEntityWithChangedStatus;
};

const getIsScheduleChanged = (schedule: MultipleEditDrawerAdsFormValues['schedule']): boolean => {
  const isDateRangeChanged = schedule?.dateRangeList.length && schedule.dateRangeList[0].startDate !== null;
  const isDayPartListChanged = schedule?.dayPartList !== null;
  const isTimezoneChanged = !!schedule?.timezone;
  const isDeliveryInViewerTimeChanges = !!schedule?.deliveryInViewerTime;

  return isDateRangeChanged || isDayPartListChanged || isTimezoneChanged || isDeliveryInViewerTimeChanges;
};

export const getIsSaveButtonDisabled = ({ values, isLineItem }: Props): boolean => {
  if (!values.selectedSections.length) {
    return true;
  }

  if (!isLineItem) {
    const { targetingTermValues, adTagList, rating, schedule } = values as MultipleEditDrawerAdsFormValues;

    const isStatusChanged = getIsStatusChanged<MultipleEditDrawerAdsFormValues['entities'][0]>(
      values as MultipleEditDrawerAdsFormValues
    );
    const isTargetingHasChanges = values.selectedSections.includes('Targeting') && targetingTermValues.length;
    const isAdTagsHaveChanges = values.selectedSections.includes('Tags') && adTagList.length;
    const isRatingHasChanges = values.selectedSections.includes('Rating') && rating !== '';
    const isScheduleHasChanges = values.selectedSections.includes('Scheduling') && getIsScheduleChanged(schedule);

    return (
      !isTargetingHasChanges && !isAdTagsHaveChanges && !isRatingHasChanges && !isStatusChanged && !isScheduleHasChanges
    );
  }

  const { targetingTermValues, buffer, priority } = values as MultipleEditDrawerLineItemsFormValues;
  const isStatusChanged = getIsStatusChanged<MultipleEditDrawerLineItemsFormValues['entities'][0]>(values);

  const isTargetingHasChanges = values.selectedSections.includes('Targeting') && targetingTermValues.length;

  const isBufferHasChanges =
    values.selectedSections.includes('Buffer & Frontload') &&
    (buffer.deliveryBufferPercent !== null || buffer.frontLoadPercent !== null);

  const isPriorityHasChanges = values.selectedSections.includes('Priority') && priority.priority.name;

  const isFrequencySelected = values.selectedSections.includes('Frequency');

  return (
    !isStatusChanged && !isTargetingHasChanges && !isBufferHasChanges && !isPriorityHasChanges && !isFrequencySelected
  );
};

export const getEntitiesWithOriginalProps = <T extends Entity>({
  entities,
  initialStatuses,
}: FormValuesWithoutSelectedSections<T>): FormValuesWithoutSelectedSections<T>['entities'] =>
  entities.map((entity) => {
    const { isSelected: originalIsSelected, status: originalStatus } = initialStatuses.find(
      (initialStatus) => initialStatus.id === entity.id
    )!;

    return { ...entity, status: originalStatus, isSelected: originalIsSelected };
  });
