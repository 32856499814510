import { gql } from '@apollo/client';

import { LINE_ITEM_TRAFFICKING_COLUMNS } from '../fragments';

export const GET_LINEITEMS_BY_CAMPAIGNID_LIST = gql`
  query GET_LINEITEMS_BY_CAMPAIGNID_LIST(
    $adProductList: [String!]
    $pacingRiskList: [String!]
    $hasMisalignedSchedule: Boolean
    $pacingStatusList: [String!]
    $reviewStatusList: [String!]
    $adStatusList: [String!]
    $ids: [ID!]!
    $campaignIds: [ID!]!
    $traffickerEmailList: [String!]
    $accountExecutiveList: [String!]
    $orderTypeList: [String!]
    $tierNameList: [String!]
    $priorityValueList: [String!]
    $billableThirdPartyList: [String!]
    $publisherList: [String!]
    $statusList: [String!]
    $subStatusList: [String!]
    $adRatingList: [String!]
    $adTypeList: [String!]
    $creativeTypeList: [String!]
    $creativeReviewList: [String!]
    $isCoppaOrCaru: Boolean
    $isMakegood: Boolean
    $isAddedValue: Boolean
    $brandIdList: [ID!]
    $agencyIdList: [ID!]
    $industryIdList: [ID!]
    $currencyList: [String!]
    $isRotation: Boolean
    $isSequence: Boolean
    $countryList: [String!]
    $schedule: ScheduleFilterInputV5
    $sort: LineItemSortOptionV5
    $order: OrderOption
    $limit: Int
    $offset: Int
    $searchTerm: String
  ) {
    lineItems: lineItemsV5(
      filterOptions: {
        selectedIdList: $ids
        campaignIdList: $campaignIds
        traffickerEmailList: $traffickerEmailList
        accountExecutiveList: $accountExecutiveList
        schedule: $schedule
        searchTerm: $searchTerm
        adProductList: $adProductList
        pacingRiskList: $pacingRiskList
        hasMisalignedSchedule: $hasMisalignedSchedule
        pacingStatusList: $pacingStatusList
        reviewStatusList: $reviewStatusList
        adStatusList: $adStatusList
        orderTypeList: $orderTypeList
        statusList: $statusList
        subStatusList: $subStatusList
        tierNameList: $tierNameList
        priorityValueList: $priorityValueList
        billableThirdPartyList: $billableThirdPartyList
        publisherList: $publisherList
        adRatingList: $adRatingList
        adTypeList: $adTypeList
        creativeTypeList: $creativeTypeList
        creativeReviewList: $creativeReviewList
        isCoppaOrCaru: $isCoppaOrCaru
        isMakegood: $isMakegood
        isAddedValue: $isAddedValue
        brandIdList: $brandIdList
        agencyIdList: $agencyIdList
        industryIdList: $industryIdList
        currencyList: $currencyList
        isRotation: $isRotation
        isSequence: $isSequence
        countryList: $countryList
      }
      sortOptions: { sort: $sort, order: $order }
      paginationOptions: { limit: $limit, offset: $offset }
    ) {
      edges {
        node {
          ...lineItemTraffickingColumns
        }
      }
      total
    }
  }
  ${LINE_ITEM_TRAFFICKING_COLUMNS}
`;
