import React from 'react';

import Loader from '../common/Loader';

const RefreshComponent = (): JSX.Element => {
  return (
    <>
      {window.location.reload()}
      <Loader className={'main'} />
    </>
  );
};

export default RefreshComponent;
