import React, { useMemo } from 'react';
import type { DeselectOptionActionMeta, InputActionMeta, SelectOptionActionMeta } from 'react-select';

import type { Nullable, TargetingTermValue, TargetingValue } from '../../../models';
import MenuList from '../../../pages/Trafficking/TableFilterBar/MenuList';
import type { TargetingValueOption } from '../../AdForm/adFormik';
import { SelectInputAction } from '../../constants/select';
import type { DropdownOption } from '../../Dropdown';
import { commonCustomComponents } from '../../Dropdown/Dropdown';
import MultiSelectDropdown from '../../MultiSelectDropdown';
import { useTargetingValuesDropdown } from '../hooks/useTargetingValuesDropdown';
import type { MultiSelectTargetingValue } from '../types';
import { getEmptyTextMessage, getNoOptionsMessage } from '../utils';

export type Props = {
  onClose: () => void;
  handleSelect: (
    options: MultiSelectTargetingValue,
    meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>
  ) => void;
  handleSelectAll: (options: MultiSelectTargetingValue) => void;
  handleClearAll: () => void;
  selectedValues: TargetingValueOption[];
  selectedDimension: Nullable<DropdownOption>;
  readonly?: boolean;
  errMsg?: string;
  dataTestId?: string;
  countryOption?: TargetingTermValue;
  publisherOption?: TargetingValue;
};

const TargetingValuesDropdown = ({
  readonly,
  onClose,
  handleSelect,
  handleSelectAll,
  handleClearAll,
  selectedValues,
  selectedDimension,
  errMsg,
  dataTestId,
  countryOption,
  publisherOption,
}: Props): JSX.Element => {
  const {
    options,
    error,
    loading,
    search,
    searchTerm,
    reset,
    onNext,
    hasMore,
    filterOption,
    supportEmptySearch,
    searching,
  } = useTargetingValuesDropdown(selectedDimension?.id, countryOption, publisherOption);

  const components = useMemo(() => ({ ...commonCustomComponents, MenuList }), []);

  const handleSearchValues = (value: string, { action }: InputActionMeta): void => {
    if (action === SelectInputAction.InputChange) search(value);
  };

  const handleClose = (): void => {
    onClose();
    reset();
  };

  const isDisabled = !selectedDimension || loading || readonly;

  const noOptionMessage = getNoOptionsMessage(searching, supportEmptySearch, searchTerm);

  return (
    <MultiSelectDropdown<string>
      label="Value"
      value={selectedValues}
      options={options}
      loading={loading}
      error={error?.message}
      isDisabled={isDisabled}
      filterOption={filterOption}
      onInputChange={handleSearchValues}
      multipleSelectControls={true}
      handleSelectAll={(): void => handleSelectAll(options)}
      handleClearAll={handleClearAll}
      inputValue={searchTerm}
      onNext={onNext}
      components={components}
      hasMore={hasMore}
      onClose={handleClose}
      emptyDisplayText={getEmptyTextMessage(loading, selectedDimension)}
      maxMenuHeight={400}
      onChange={handleSelect}
      noOptionsMessage={(): string => noOptionMessage}
      errMsg={errMsg}
      dataTestId={dataTestId}
      placeholder={<div>{supportEmptySearch ? 'Search' : 'Search to find options'}</div>}
    />
  );
};

export default TargetingValuesDropdown;
