import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { debounce as _debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import type {
  GetTargetingValuesByCategoryAndDimensionIdQuery,
  QueryGetTargetingValuesByCategoryAndDimensionIdArgs,
} from '../../../apis/graphql';
import type { TargetingTermValue, TargetingValue } from '../../../models';
import { GET_TARGETING_VALUE_BY_CATEGORY_AND_DIMENSION_ID } from '../../../pages/Ads/hooks/queries';
import type { AdFormValues } from '../../AdForm/adFormik';
import type { DropdownOption } from '../../Dropdown';
import { useFieldFast } from '../../Form/hooks';
import { DEFAULT_DEBOUNCE_TIMEOUT } from '../../hooks/constants';
import { DIMENSIONS_DOES_NOT_SUPPORT_EMPTY_SEARCH } from '../constants';
import { DEFAULT_TARGETING_PAGINATION_LIMIT } from './constants';
import { formatForDropdown } from './utils';

export type UseTargetingValuesDropdown = {
  options: DropdownOption[];
  loading: boolean;
  error?: ApolloError;
  search: (searchTerm: string) => void;
  reset: () => void;
  onNext: () => Promise<void>;
  hasMore: boolean;
  searchTerm: string;
  filterOption?: () => boolean;
  supportEmptySearch: boolean;
  searching: boolean;
};

export const useTargetingValuesDropdown = (
  dimensionId?: string,
  countryOption?: TargetingTermValue,
  publisherOption?: TargetingValue
): UseTargetingValuesDropdown => {
  const [, { value: newTargeting }] = useFieldFast<AdFormValues['newTargeting']>('newTargeting');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);

  const supportEmptySearch = useMemo(() => !DIMENSIONS_DOES_NOT_SUPPORT_EMPTY_SEARCH.find((id) => id === dimensionId), [
    dimensionId,
  ]);

  const getTargetingQueryVariables = useCallback(
    (offset = 0, searchTerm: string | null = null): QueryGetTargetingValuesByCategoryAndDimensionIdArgs => {
      return {
        categoryGuid: newTargeting?.category?.id || '',
        dimensionGuid: newTargeting?.dimension?.id || '',
        filterOptions: searchTerm ? { searchTerm } : null,
        paginationOptions: { limit: DEFAULT_TARGETING_PAGINATION_LIMIT, offset },
        countryOption: { valueId: countryOption?.value.id || '' },
        publisherOption: { valueId: publisherOption?.id || '' },
      };
    },
    [newTargeting?.category?.id, newTargeting?.dimension?.id, countryOption?.value.id, publisherOption?.id]
  );

  const { data, loading, error, refetch, fetchMore } = useQuery<
    GetTargetingValuesByCategoryAndDimensionIdQuery,
    QueryGetTargetingValuesByCategoryAndDimensionIdArgs
  >(GET_TARGETING_VALUE_BY_CATEGORY_AND_DIMENSION_ID, {
    variables: getTargetingQueryVariables(0, ''),
    skip: !dimensionId || !newTargeting?.category?.id,
  });

  const options = useMemo(
    () => formatForDropdown(data?.getTargetingValuesByCategoryAndDimensionID.edges.map(({ node }) => node) || []),
    [data?.getTargetingValuesByCategoryAndDimensionID.edges]
  );

  const hasMore = useMemo(() => !!data?.getTargetingValuesByCategoryAndDimensionID.pageInfo.hasNextPage, [
    data?.getTargetingValuesByCategoryAndDimensionID.pageInfo.hasNextPage,
  ]);

  const search = async (term: string): Promise<void> => {
    setSearchTerm(term);

    setSearching(true);
    await refetchTargetings(term);
  };

  const reset = async (): Promise<void> => {
    setSearchTerm('');

    await refetchTargetings();
  };

  const onNext = async (): Promise<void> => {
    try {
      const offset = data?.getTargetingValuesByCategoryAndDimensionID.edges.length || 0;
      await fetchMore({
        variables: getTargetingQueryVariables(offset, searchTerm),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const refetchTargetings = useMemo(
    () =>
      _debounce(async (searchTerm: string | null = null): Promise<void> => {
        try {
          if (!newTargeting?.category?.id || !newTargeting?.dimension?.id) return;
          if (supportEmptySearch || searchTerm) {
            await refetch(getTargetingQueryVariables(0, searchTerm));

            if (searchTerm) {
              setSearching(false);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }, DEFAULT_DEBOUNCE_TIMEOUT),
    [newTargeting?.category?.id, newTargeting?.dimension?.id, refetch, supportEmptySearch, getTargetingQueryVariables]
  );

  return {
    supportEmptySearch,
    options,
    loading,
    error,
    search,
    searchTerm,
    reset,
    onNext,
    filterOption: (): boolean => true,
    hasMore,
    searching,
  };
};
