import { TRAFFICKING_SEARCH_PARAMS, TRAFFICKING_TABLE_NAMES } from '../../../constants';
import type { NonNullableFields } from '../../../models';
import { DEFAULT_DATE_FILTER } from '../../../pages/Trafficking/TraffickingPageController';
import type { TableFilters } from './types';

const DEFAULT_TAB = TRAFFICKING_TABLE_NAMES[0]; // 'campaigns'

export const INITIAL_TABLE_FILTERS: NonNullableFields<TableFilters> = TRAFFICKING_TABLE_NAMES.reduce(
  (acc, tableName) => {
    return {
      ...acc,
      [tableName]: {
        searchTerm: '',
        selectedRowIds: [],
        sortBy: null,
      },
    };
  },
  {} as NonNullableFields<TableFilters>
);

export const TRAFFICKING_FILTERS_SEARCH_PARAMS = [
  TRAFFICKING_SEARCH_PARAMS.SELECTED_TAB,
  TRAFFICKING_SEARCH_PARAMS.DATE_FILTER,
  TRAFFICKING_SEARCH_PARAMS.LINE_ITEMS_SELECTED_IDS,
  TRAFFICKING_SEARCH_PARAMS.ADS_ITEMS_SELECTED_IDS,
];

export const TRAFFICKING_INITIAL_TABLE_FILTERS = [
  TRAFFICKING_SEARCH_PARAMS.DATE_FILTER,
  TRAFFICKING_SEARCH_PARAMS.SELECTED_TAB,
];

export const INITIAL_FILTERS = {
  [TRAFFICKING_SEARCH_PARAMS.DATE_FILTER]: DEFAULT_DATE_FILTER,
  [TRAFFICKING_SEARCH_PARAMS.SELECTED_TAB]: DEFAULT_TAB,
  [TRAFFICKING_SEARCH_PARAMS.ACCOUNT_EXECUTIVE]: null,
  [TRAFFICKING_SEARCH_PARAMS.PRODUCT_TYPE]: null,
  [TRAFFICKING_SEARCH_PARAMS.ACCOUNT_MANAGER]: null,
  [TRAFFICKING_SEARCH_PARAMS.PACING_RISK_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.HAS_MISALIGNED_SCHEDULE]: null,
  [TRAFFICKING_SEARCH_PARAMS.PACING_STATUS_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.REVIEW_STATUS_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.ORDER_TYPE_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.AD_STATUS_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.LINE_ITEM_STATUS_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.TIER_NAME_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.PRIORITY_VALUE_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.BILLABLE_THIRD_PARTY_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.PUBLISHER_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.AD_RATING_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.AD_TYPE_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.CREATIVE_TYPE_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.CREATIVE_REVIEW_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.IS_COPPA_OR_CARRU]: null,
  [TRAFFICKING_SEARCH_PARAMS.IS_MAKEGOOD]: null,
  [TRAFFICKING_SEARCH_PARAMS.IS_ADDED_VALUE]: null,
  [TRAFFICKING_SEARCH_PARAMS.BRAND_ID_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.AGENCY_ID_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.INDUSTRY_ID_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.CURRENCY_LIST]: null,
  [TRAFFICKING_SEARCH_PARAMS.IS_ROTATION]: null,
  [TRAFFICKING_SEARCH_PARAMS.IS_SEQUENCE]: null,
  [TRAFFICKING_SEARCH_PARAMS.COUNTRY_LIST]: null,
  ...INITIAL_TABLE_FILTERS,
} as const;
